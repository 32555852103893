.invoices {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 15px;
  max-height: 200vh;
  overflow-y: auto;
}

.invoices-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.invoices-title {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.total-invoices {
  font-size: 1.2rem;
  font-weight: bold;
  color: #045096;
  font-family: 'Poppins', sans-serif;
}

.invoices-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.unread-dot {
  width: 15px;
  height: 15px;
  background-color: #045096;
  border-radius: 50%;
  position: relative;
  top: -35px;
  right: 22px;
  margin-right: -18px;
}

.invoice-item.unread {
  font-weight: bold;
  border: 1.5px solid #045096;
}

.invoice-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.invoice-item:hover {
  background-color: #f1f1f1;
  border-color: #045096;
}

.invoice-company-name {
  font-size: 1.2rem;
  font-weight: bold;
  flex: 1;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.amount-due {
  font-size: 1.2rem;
  font-weight: bold;
  color: #045096;
  margin-right: 20px;
  font-family: 'Poppins', sans-serif;
}

.paid-amount {
  color: #888;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 20px;
  font-family: 'Poppins', sans-serif;
}

.status-icon img {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-top: 7px;
}

.arrow-icon {
  font-size: 1.5rem;
  color: #045096;
}

.invoices::-webkit-scrollbar {
  width: 8px;
}

.invoices::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.invoices::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.invoices::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

@media (max-width: 1000px) and (min-width: 651px) {
  .invoices-title {
    font-size: 1.3rem;
  }
  
  .total-invoices {
    font-size: 1.1rem;
  }

  .invoice-company-name {
    font-size: 1rem;
  }
  
  .amount-due {
    font-size: 1rem;
  }
  
  .status-icon img {
    width: 23px;
    height: 23px;
  }
  
  .arrow-icon {
    font-size: 1.3rem;
  }
}

@media (max-width: 800px) and (min-width: 651px) {
  .amount-due {
    font-size: 0rem;
  }
}

@media (max-width: 450px) {
  .invoices-title {
    font-size: 1.3rem;
  }
  
  .total-invoices {
    font-size: 1.1rem;
  }

  .invoice-company-name {
    font-size: 1rem;
  }
  
  .amount-due {
    font-size: 1rem;
  }
  
  .status-icon img {
    width: 23px;
    height: 23px;
  }
  
  .arrow-icon {
    font-size: 1.3rem;
  }
}

@media (max-width: 350px) {
  .amount-due {
    font-size: 0rem;
  }

  .invoices-title {
    font-size: 1.15rem;
  }
  
  .total-invoices {
    font-size: 1rem;
  }

  .invoice-company-name {
    font-size: 0.9rem;
  }
}