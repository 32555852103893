.invoices {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 15px;
  max-height: 200vh;
  overflow-y: auto;
}

.invoice-user-name {
  font-size: 1.2rem;
  font-weight: bold;
  flex: 1;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.total-amount.small {
  font-size: 0.9rem;
  color: #888;
  margin-right: 20px;
  font-weight: bold;
}

.completed-resent {
  font-size: 1.2rem;
  color: #888; 
  margin-right: 20px;
  font-weight: bold;
}

.resend-button-container {
  margin-bottom: 5px;
}

.resend-button {
  border: none;
  background-color: transparent;
  color: #045096;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  margin-right: 20px;
}

.resend-button:hover {
  color: #23313d;
  text-decoration: underline;
}