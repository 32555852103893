.report-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.report-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.report-reason-step, .report-details-step, .report-submitted-message {
  text-align: center;
}

.report-reasons-list {
  list-style: none;
  padding: 0;
}

.report-reasons-list li {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  margin: 10px 0;
  border-radius: 4px;
  transition: background 0.3s;
}

.report-reasons-list li:hover {
  background: #f0f0f0;
}

.report-details-step div {
  text-align: left;
  margin-bottom: -17px;
  margin-left: 10px;
}

.report-details-step textarea {
  width: 90%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 20px 0;
  font-family: 'Poppins', sans-serif;
}

.report-buttons {
  display: flex;
  justify-content: space-between;
}

.report-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.report-buttons button:first-child {
  background: #f0f0f0;
}

.report-buttons button:last-child {
  background: #045096;
  color: white;
}

.report-submitted-message {
  text-align: center;
}
