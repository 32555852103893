.send-invoice-container {
  background-color: white;
}

.send-invoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 150px;
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  max-width: 60%;
  margin: 50px auto;
  border: 1px solid #e3e3e3;
}

.send-invoice-header {
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  margin-top: -20px;
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.7rem;
}

.create-invoice-form {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 15px;
  max-width: 500px;
  width: 100%;
}

.create-invoice-form input[type='text'],
.create-invoice-form input[type='date'],
.create-invoice-form select,
.create-invoice-form input[type='number'] {
  width: 100%; 
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.date-picker-container label {
  margin-left: 1%;
  font-family: 'Poppins', sans-serif;
}

.create-invoice-form .send-invoice-react-select {
  width: 100%; 
  margin-bottom: 15px;
  font-size: 1rem;
  text-align: left;
}

.send-react-select__control {
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

.send-react-select__input input {
  position: relative;
  display: flex;
  font-family: 'Poppins', sans-serif;
}

.send-react-select__control:hover,
.send-react-select__control--is-focused {
  border-color: #23313d !important;
}

.send-invoice-react-select {
  width: 100%;
  margin-bottom: 15px;
}

.main-react-select__control {
  border-radius: 5px;
  border: 3px solid #045096;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.main-react-select__input input {
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.main-react-select__control:hover,
.main-react-select__control--is-focused {
  border-color: #23313d !important;
}

.main-react-select__single-value {
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.main-react-select__option {
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.main-react-select__option--is-focused {
  background-color: #e6f0f9;
}

.main-react-select__option--is-selected {
  background-color: #045096;
  color: white;
}

.create-invoice-form label {
  display: block;
  margin-bottom: 15px;
  font-size: 1rem;
  color: #045096;
  text-align: left;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1%;
  margin-bottom: 30px;
}

.switch {
  display: inline-block;
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: -7px;
}

.switch.off {
  background-color: #4caf50;
}

.switch.on {
  background-color: #ccc;
}

.toggle {
  width: 23px;
  height: 23px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: left 0.3s;
}

.switch.on .toggle {
  left: 26px;
}

.invoice-amount-dropdown {
  margin-top: 35px;
  margin-bottom: -15px;
}

.create-invoice-form button {
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: calc(60% - 10px);
  transition: all 0.3s ease;
  margin-top: 25px;
}

.create-invoice-form button:hover {
  border-color: #23313d;
  color: #23313d;
}

.create-invoice-form .file-input-label {
  display: inline-block;
  background-color: #045096;
  color: white;
  padding: 10px 15px;
  font-family: 'Inter-SemiBoldItalic', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 5px;
  display: block;
  text-align: center;
  width: 150px;
  margin-left: 1%;
  transition: background-color 0.3s;
}

.create-invoice-form .file-input-label:hover {
  background-color: #23313d;
}

.create-invoice-form .file-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .create-invoice-form input[type='text'],
  .create-invoice-form input[type='date'],
  .create-invoice-form input[type='number'],
  .create-invoice-form select,
  .create-invoice-form button {
    width: 100%;
  }

  .create-invoice-form .method {
    font-size: 1.6rem;
  }
}
