.invoice-page {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 5%;
}

.profile-section {
  width: 46%;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
}

.invoices-section {
  width: 46%;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
}

.profile-invoice,
.current-invoice,
.payment-history {
  margin-bottom: 20px;
}

.invoices-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

@media (max-width: 650px) {
  .invoice-page {
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 97%;
  }

  .profile-section,
  .invoices-section {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    padding: 0px;
  }
}
