.body-not-found {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #F1F1F1;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-container {
  position: relative;
  text-align: center;
  max-width: 80%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nf-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.nf-logo {
  width: 50px;
  height: auto;
  margin-right: 20px;
}

h1 {
  font-size: 3rem;
  margin: 0;
}

p {
  font-size: 1.5rem;
  margin: 20px 0;
}

.bottom-row {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 3px solid #045096;
  background-color: transparent;
  color: #045096;
  transition: all 0.3s ease;
  margin-left: 75px; 
  margin-right: 10px; 
}

.button:hover {
  background-color: transparent;
  border-color: #23313d;
  color: #23313d; 
}

.broken-sink {
  margin-left: 30px; 
  width: 80px; 
  height: auto;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
  }

  .button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .broken-sink {
    width: 60px;
    margin-left: 10px;
  }

  .logo {
    width: 40px;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .button {
    margin-left: 65px; 
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

  .button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }

  .broken-sink {
    width: 50px;
    margin-left: 5px;
  }

  .logo {
    width: 30px;
    margin-right: 5px;
  }
}
