.search-function {
  flex: 1;
  margin: 0 20px;
  position: relative;
}

.search-function input {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

.search-function ul {
  list-style-type: none;
  padding: 0;
  margin: 5px 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.search-function li {
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-function li:hover {
  background-color: #f5f5f5;
}

.search-function li:last-child {
  border-bottom: none;
}

.search-function img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.search-function div {
  text-align: left;
}

.search-function strong {
  font-size: 1rem;
  margin-right: 10px;
}

.search-function p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  text-align: left;
}

.search-function span {
  margin-left: auto;
  font-size: 0.85rem;
  color: #999;
}

@media (max-width: 1000px) {
  .search-function input {
    width: 90%;
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .search-function input {
    width: 100%;
    font-size: 14px;
  }
}
