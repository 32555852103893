.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 700px;
  height: 80%;
  max-width: 90%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 7px;
  right: 10px;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: black;
  background-color: white;
}

.modal-top-bar {
  display: flex;
  text-align: center;
  padding: 10px 30px;
  border-bottom: 1px solid #ccc;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 0px;
}

.modal-top-bar h2 {
  margin-left: 7%;
  margin-right: 20px;
}

.back-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #045096;
  display: flex;
  align-items: center;
}

.back-button:hover {
  background: none;
  color: #23313d;
}

.message-header-info {
  display: flex;
  margin-top: 10px;
  margin-bottom: 7px;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
}

.message-header-info .name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-left: 15px;
}

.message-header-info .name:hover {
  text-decoration: underline;
}

.header-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.new-message-container {
  padding: 10px;
  background: white;
  display: flex;
  align-items: center;
  border-top: 1px solid #ccc;
}

.new-message-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.attach-button {
  background: none;
  border: none;
  margin-right: 10px;
  cursor: pointer;
}

.attach-icon {
  width: 24px;
  height: 24px;
}

.attachment-preview-container {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  flex-wrap: wrap;
}

.attachment-preview {
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 0px;
}

.attachment-image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 4px;
}

.remove-attachment-button {
  position: absolute;
  margin-top: -10;
  right: 0;
  background: #666;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 4px;
  padding: 0px 0px;
  font-size: 12px;
}

.new-message-container textarea {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
  height: 45px;
  box-sizing: border-box;
  resize: none;
  overflow: hidden;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  align-content: center;
  max-height: 120px;
  overflow-y: auto;
}

.message-send-icon {
  height: 25px;
  width: auto;
  rotate: 270deg;
}

.message-send-button {
  width: 70px;
  height: 35px;
  border-radius: 10px;
  background-color: white;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #23313d;
  justify-content: center;
}

.message-send-button:hover {
  background-color: #e9e9e9;
}

.message-attachment-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.message-attachment-sent {
  align-items: flex-end;
}

.message-attachment-received {
  align-items: flex-start;
}

.message-attachment-image-style {
  width: 150px;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin: 0px 0;
}

.message-attachment-wrapper {
  cursor: pointer;
}

.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}

.messages-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}

.message-modal {
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
  max-width: 70%;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  position: relative;
  word-wrap: break-word;
  display: inline-block;
  clear: both;
  white-space: pre-wrap;
}

.message-sent {
  background: #045096;
  color: white;
  float: right;
  text-align: left;
}

.message-received {
  background: #f0f0f0;
  color: #045096;
  float: left;
  text-align: left;
}

.message-time {
  font-size: 0.75rem;
  color: #999;
  margin-top: 2px;
  clear: both;
}

.time-sent {
  text-align: right;
  margin-bottom: 5px;
}

.time-received {
  text-align: left;
  margin-bottom: 5px;
}

.conversations-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.conversation-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
}

.conversation-item.unread {
  font-weight: bold;
  border: 1.5px solid #045096;
}

.unread-indicator {
  position: absolute;
  top: 0;
  right: -1;
  width: 10px;
  height: 10px;
  background-color: #045096;
  border: 0.8px solid white;
  border-radius: 50%;
}

.conversation-item:hover {
  background-color: #f1f1f1;
  border-color: #045096;
}

.three-dots-horizontal {
  cursor: pointer;
  font-size: 1.5rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 42%;
  left: 43%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.message-dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 100;
  top: 60px;
  left: 0;
}

.message-dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
}

.message-dropdown-item:hover {
  background-color: #f1f1f1;
}

.conversation-profile-wrapper {
  position: relative;
}

.conversation-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  transition: filter 0.3s ease;
}

.conversation-profile-wrapper.blur .conversation-profile-picture {
  filter: blur(14px);
  color: white;
  width: 40px;
  height: 40px;
}

.conversation-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

.conversation-name-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.conversation-name {
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conversation-date {
  font-size: 0.85rem;
  color: #999;
}

.conversation-last-message {
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.block-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.block-message {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.block-message h3 {
  font-size: 18px;
}

@media (max-width: 500px) {
  .modal-content {
    width: 90%;
    height: 90%;
  }
}

.conversations-list::-webkit-scrollbar,
.new-messages-container textarea::-webkit-scrollbar,
.messages-container::-webkit-scrollbar {
  width: 8px;
}

.conversations-list::-webkit-scrollbar-thumb,
.new-messages-container textarea::-webkit-scrollbar-thumb,
.messages-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.conversations-list::-webkit-scrollbar-thumb:hover,
.new-messages-container textarea::-webkit-scrollbar-thumb:hover,
.messages-container::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.conversations-list::-webkit-scrollbar-track,
.new-messages-container textarea::-webkit-scrollbar-track,
.messages-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .modal-top-bar h2 {
    margin-left: 0%;
    font-size: 1.3rem;
  }

  .message-header-info {
    margin-left: 20px;
  }
  
  .message-header-info .name {
    font-size: 1rem;
  }

  .message-send-button {
    font-size: 1rem;
  }

  .new-message-container textarea {
    font-size: 13px;
  }

  .message-send-icon {
    height: 20px;
    width: auto;
  }

  .message-time {
    font-size: 0.6rem;

  }
}