.current-invoice {
  background-color: #f9f9f9;
}

.current-invoice-cancel-button {
  --primary-color: #d9534f;
  --hovered-color: #c9302c;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  gap: 0.5rem;
  align-items: center;
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 5px;
  color: var(--primary-color);
  transition: all 0.3s ease;
  align-self: flex-end;
}

.current-invoice-cancel-button p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--primary-color);
}

.current-invoice-cancel-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.current-invoice-cancel-button p::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.current-invoice-cancel-button:hover::after,
.current-invoice-cancel-button.active::after {
  width: 100%;
}

.current-invoice-cancel-button:hover p::before,
.current-invoice-cancel-button.active p::before {
  width: 100%;
}

.current-invoice-cancel-button:hover svg,
.current-invoice-cancel-button.active svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.current-invoice-cancel-button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.current-invoice-cancel-button.active {
  --primary-color: var(--hovered-color);
}

.confirm-payment-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-payment-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 90%;
  width: 400px;
}

.confirm-payment-modal-content h2 {
  margin-bottom: 40px;
}

.confirm-payment-modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-left: 2.5%;
  height: 50px;
  width: 95%;
  background-color: white;
  border-radius: 15px 15px 15px 15px;
  z-index: 3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #b0b0b0;
  border-right: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
}

.confirm-payment-confirm-button {
  color: #fb3e3e;
  padding: 10px 35px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.confirm-payment-confirm-button:hover {
  background-color: #f1f0f0;
  color: #ff0000;
}

.confirm-payment-cancel-button { 
  color: #045096;
  padding: 10px 60px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.confirm-payment-cancel-button:hover {
  background-color: #f1f0f0;
  color: #23313d;
}