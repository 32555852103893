.contact-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px;
  max-width: 70%;
  margin: 0 auto;
}

.contact-header {
  font-size: 3.5em;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  width: 35%;
  text-align: left;
}

.contact-form {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 1.2em;
  margin-bottom: 5px;
  display: block;
  text-align: left;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}

.form-group textarea {
  height: 150px;
  resize: none;
  font-family: 'Poppins', sans-serif;
}

.submit-button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #333;
  color: #fff;
  border: none;
  width: 30%;
  border-radius: 10px;
  cursor: pointer;
}

.success-message {
  margin-top: 20px;
  color: green;
  font-size: 1.2em;
  font-weight: bold;
}

@media (max-width: 750px) {
  .contact-page {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 100%;
  }

  .contact-header {
    font-size: 2.3em;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .contact-form {
    width: 90%;
  }

  .form-group label {
    font-size: 1em;
  }

  .form-group input,
  .form-group textarea {
    font-size: 0.9em;
  }

  .submit-button {
    width: 30%;
    font-size: 0.9em;
  }

  .success-message {
    font-size: 1em;
  }
}
