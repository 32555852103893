.company-highlights-container {
  background-color: #f1f0f0;
}

.ex-no-highlights-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.ex-no-highlights-container p {
  font-size: 1.7rem;
  color: #666;
  font-family: 'Fredoka One', cursive;
}