.Header {
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #b0b0b0;
}

.searched-header-width {
  display: flex;
  justify-content: space-between;
  height: 55px;
  max-width: 60%;
  margin-right: 7.7%;
  width: 100%;
  align-items: center;
}

.brand-header {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 700;
  margin-left: 15px;
  color: #045096;
  cursor: pointer;
}

.header-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

.brand-name {
  display: inline;
  margin-bottom: 10px;
}

.brand-name:hover {
  cursor: pointer;
}

.header-search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  padding: 10px;
  background-color: transparent;
}

.header-combined-view {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 15px;
  width: 100%;
}

.header-search-group {
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 15px;
  height: 40px;
  padding: 5px;
  width: 100%;
}

.header-react-select__option {
  text-align: left;
}

.react-header-search-location,
.react-header-search-type {
  border: none;
  flex: 1;
  background: #f1f0f0;
  outline: none;
  font-size: 14px;
  border-radius: 10px;
  height: 40px;
  margin-right: 3px;
  margin-top: 2px;
}

.header-search-input {
  border: none;
  flex: 1;
  background: #f1f0f0;
  outline: none;
  font-size: 14px;
  border-radius: 10px;
  height: 40px;
  padding: 0.5rem;
}

.react-header-search-input {
  background-color: transparent;
}

.header-react-select__input input {
  display: flex;
  align-items: center;
}

.header-react-select__single-value {
  display: flex;
  align-items: center;
  height: 22px;
}

.header-react-select__option {
  display: flex;
  align-items: center;
}

.header-search-button {
  border: 1px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 5px 10px;
  border-radius: 10px;
  margin-left: 5px;
  cursor: pointer;
  height: 30px;
}

.header-search-button:hover {
  background-color: #f1f0f0;
}

.header-filter-button,
.header-adjust-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.header-filter-button img {
  width: 26px;
  height: 26px;
  padding: 5px;
  margin-top: 3px;
  border-radius: 10px;
  border: 1px solid #b0b0b0;
}

.header-filter-button img:hover {
  background-color: #f1f0f0;
}

.header-location-icon {
  width: 26px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  background-color: #f1f0f0;;
  margin-right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.header-location-input {
  display: flex;
  align-items: center;
  position: relative;
}

.header-user-nav {
  display: flex;
  justify-content: right;
}

.header-nav-button {
  background: #23313d;
  font-family: 'Raleway', sans-serif;
  font-size: 1.1rem;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: right;
}

.header-sign-in-button {
  border-radius: 12px;
  padding: 0.6rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}

.header-profile-button {
  position: relative;
}

.header-profile-button img.header-profile-picture {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.header-profile-button img.header-profile-picture:hover {
  border: 1px solid #23313d;
}

.header-sign-in-button:hover {
  background-color: #333a41;
  color: white;
}

.searched-header-dropdown-menu {
  position: absolute;
  top: 55px;
  right: -157px;
  background: white;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  width: 200px;
  z-index: 999;
}

.header-dropdown-profile-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
}

.header-dropdown-profile-text span {
  display: block;
}

.header-dropdown-profile-text .view-profile {
  font-weight: bold;
}

.header-dropdown-profile-text .dropdown-username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px; 
  position: relative;
  padding-right: 1em; 
}

.searched-header-dropdown-menu {
  top: 50px;
  left: -10px;
  margin-right: 0px;
  background: white;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 999;
}

.header-dropdown-profile {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #b0b0b0;
  cursor: pointer;
}

.header-dropdown-profile img.header-dropdown-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.searched-header-dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.searched-header-dropdown-menu ul li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.searched-header-dropdown-menu ul li:hover {
  background-color: #f1f1f1;
}

.searched-header-dropdown-menu ul li img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}


@media (max-width: 1200px) and (min-width: 1001px) {
  .header-sign-in-button {
    width: 81px;
  }
}

@media (max-width: 1000px) {
  .searched-header-width {
    padding-right: -10%;
    padding-left: -10%;
    align-items: center;
    width: 100%;
    max-width: 97%;
  }

  .searched-header-dropdown-menu {
    position: absolute;
    top: 35px;
    left: -160px;
    background: white;
    border: 1px solid #b0b0b0;
    border-radius: 5px;
    width: 200px;
    z-index: 999;
  }

  .header-user-nav {
    margin-right: -10px;
  }

  .header-sign-in-button {
    width: 81px;
  }
}

@media (max-width: 750px) {
  .header-search-bar {
    position: relative;
    z-index: 1000;
  }

  .header-search-group {
    position: fixed;
    top: 55px;
    left: 15px;
    right: 0;
    background: white;
    padding: 7px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 90%;
    gap: 0;
    display: flex;
    justify-content: space-around;
  }

  .header-search-group .react-header-search-input,
  .header-location-input {
    flex: 1;
    margin-right: 10px;
  }

  .select-group {
    flex-direction: row; 
    gap: 0; 
    width: 100%;
    font-size: 1rem;
  }

  .header-search-group {
    gap: 0px;
  }

  .react-header-search-type .header-react-select__control {
    border-radius: 15px 0 0 15px;
    border-right: none;
    margin-right: -3px;
  }
  
  .react-header-search-location .header-react-select__control {
    border-radius: 0 15px 15px 0;
    border-left: none; 
  }

  .header-location-icon {
    position: absolute;
    right: 10px;
    width: 0px;
    height: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  .header-search-button {
    margin-left: -10px;
  }
}

@media (max-width: 630px) {
  .searched-header-width {
    height: 45px;
    max-width: 97%;
  }

  .brand-header {
    font-size: 1.7rem;
  }

  .header-logo {
    width: 35px;
    height: auto;
  }

  .header-nav-button {
    font-size: 0.95rem;
  }

  .header-sign-in-button {
    padding: 0.2rem 0.8rem;
  }

  .header-profile-button img.header-profile-picture {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 400px) {
  .Header {
    height: 40px;
  }

  .searched-header-width {
    height: 26px;
    max-width: 97%;
    margin-top: 9px;
  }

  .brand-header {
    font-size: 1.5rem;
  }

  .brand-name { 
    margin-left: -5px;
  }

  .header-logo {
    width: 25px;
    height: auto;
  }

  .header-nav-button {
    font-size: 0.8rem;
  }

  .header-sign-in-button {
    padding: 0.4rem 0.7rem;
  }

  .header-profile-button img.header-profile-picture {
    width: 25px;
    height: 25px;
  }

  .header-combined-view {
    height: 15px;
  }

  .header-filter-button img {
    width: 20px;
    height: 20px;
  }

  .header-dropdown-menu {
    right: -145px;
  }
}