.footer {
  background-color: white;
  color: black;
  border-top: 1px solid #b0b0b0;
  font-family: 'Poppins', sans-serif;
  padding: 50px 20px;
}

.footer-wrapper {
  max-width: 75%;
  margin: 0 auto;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.footer-company,
.footer-contact,
.footer-links,
.social-icons {
  flex: 1;
  min-width: 180px;
  margin: 10px;
}

.footer-company {
  display: flex;
  flex-direction: column;
  margin-right: 4%;
}

.company-header {
  display: flex;
  align-items: center;
}

.company-logo {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.footer-company-name {
  font-size: 1.5rem;
  margin: 0;
}

.mission-statement {
  font-size: 1rem;
  margin: 10px 0;
  text-align: left;
  margin-bottom: 40px;
  color: #555;
}

.download-apps {
  display: flex;
  flex-direction: column;
}

.download-title {
  font-size: 1.1rem;
  margin-bottom: 10px;
  text-align: left;
}

.app-icons {
  display: flex;
  gap: 20px;
}

.app-icon {
  width: 30px;
  height: auto;
}

.footer-contact span,
.footer-contact a {
  color: #555;
  text-decoration: none;
}

.footer-contact {
  text-align: left;
  margin-right: 5%;
}

.contact-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.footer-links-title {
  font-size: 1.5rem;
  text-align: left;
}

.footer-links-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links-list li {
  margin: 0.7rem 0;
  text-align: left;
}

.footer-links-list li a {
  color: #555;
  text-decoration: none;
  transition: color 0.1s ease;
}

.footer-links-list li a:hover {
  color: #045096;
}

.social-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 0px;
}

.social-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.social-icon {
  width: 23px;
  height: auto;
  margin-bottom: 10px;
}

.footer-bottom {
  text-align: left;
  margin-top: 20px;
  width: 100%;
}

.footer-border {
  border-top: 1px solid #b0b0b0;
  margin: 20px 0;
}

.footer-bottom p {
  font-size: 1rem;
  color: #555;
  margin-top: 0px;
  margin-bottom: 0px;
}
