.user-not-found {
  font-size: 1.5rem;
  margin-bottom: 400px;
  margin-top: 10px;
}

.ex-user-white-background-container {
  position: relative;
  margin-right: 8%;
  top: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #b0b0b0;
  border-right: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
  border-radius: 0 0 15px 15px;
  padding-top: 14.5%;
}

.message-button {
  position: absolute; 
  top: 5px;
  right: 7px; 
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Raleway', sans-serif;
  z-index: 3;
}

.message-button:hover {
  border-color: #23313d;
  color: #23313d;
}

@media (max-width: 1000px) {
  .ex-user-white-background-container {
    margin-right: 0%;
  }
}

@media (max-width: 768px) {
  .message-button {
    padding: 7px 16px;
  }
}

@media (max-width: 650px) {
  .message-button {
    padding: 7px 14px;
    font-size: 11px;
    margin-right: -20px;
  }
}

@media (max-width: 500px) {
  .message-button {
    padding: 7px 11px;
    font-size: 9px;
  }
}