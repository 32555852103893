.default-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.default-modal-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 500px;
  z-index: 1001;
  position: relative; 
  overflow-y: auto; 
  max-height: 80vh;
}

.default-backgrounds {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
}

.default-background-option {
  border: 2px solid #e3e3e3;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s;
}

.default-background-option:hover {
  border-color: #045096;
}

.default-background-option img {
  width: 100%;
  height: auto;
  display: block;
}

.close {
  float: right;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.default-modal-container::-webkit-scrollbar {
  width: 8px;
}

.default-modal-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.default-modal-container::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.default-modal-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}