.payment-message-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9990;
}

.payment-message-modal-content {
  text-align: center;
  background-color: #f1f1f1;
  padding: 20px;
  border-radius: 10px;
  width: 30%;
  max-width: 90%;
}

.payment-message-modal-header {
  color: #045096;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.payment-message-modal-message {
  color: #333;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

.payment-message-modal-dismiss-button {
  color: #045096;
  padding: 10px 60px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: #f1f0f0;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.payment-message-modal-dismiss-button:hover {
  background-color: white;
  color: #23313d;
}

@media (max-width: 1000px) {
    .payment-message-modal-content {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    .payment-message-modal-content {
      width: 80%;
    }
  }
  
  @media (max-width: 550px) {
    .payment-message-modal-header {
      font-size: 1.2rem;
    }
  
    .payment-message-modal-message {
      font-size: 0.9rem;
    }
  
    .payment-message-modal-dismiss-button {
      padding: 6px 30px;
      font-size: 0.85rem;
    }

}