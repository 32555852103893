.mainframe {
  box-sizing: border-box;
  background: #f1f0f0;
}

.search-group-display {
  justify-content: center;
  align-items: center;
  display: flex;
}

.search-group {
  display: flex;
  align-items: center;
  gap: 1rem; 
  margin-top: 7%;
  margin-bottom: 30%;
  width: 60%;
  font-family: 'Poppins', sans-serif;
}

.select-group {
  display: flex;
  align-items: center;
  gap: 1rem; 
  width: 100%; 
}

.location-react-select,
.type-react-select {
  flex: 1; 
}

.main-react-select__control {
  border-radius: 5px;
  border: 3px solid #045096;
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.main-react-select__input input {
  position: relative;
  display: flex;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

.main-react-select__control:hover,
.main-react-select__control--is-focused {
  border-color: #23313d !important;
}

.main-react-select__single-value {
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.main-react-select__option {
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.input-error {
  border-color: #d3211b !important;
}

.location-input {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1; 
}

.location-icon {
  position: absolute;
  right: 10px;
  background-size: cover;
  width: 30px; 
  height: 30px; 
  cursor: pointer;
  background-color: white;
}

.search-button {
  font-family: 'Raleway', sans-serif;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 1.4rem;
  border: 3px solid #045096;
  background-color: transparent;
  color: #045096;
  transition: all 0.3s ease;
}

.search-button:hover {
  background-color: transparent;
  border-color: #23313d;
  color: #23313d; 
}

@media (max-width: 800px) {
  .search-group {
    flex-direction: column; 
    align-items: stretch; 
    width: 95%;
    gap: 0;
    margin-top: 5%;
    margin-bottom: 90%;
  }

  .select-group {
    flex-direction: row; 
    gap: 0; 
    width: 100%;
    font-size: 1rem;
  }

  .main-react-select__control,
  .main-react-select__input input,
  .main-react-select__control {
    font-size: 0.7rem;
  }

  .type-react-select .main-react-select__control {
    border-radius: 15px 0 0 15px;
    border-right: none;
  }
  
  .location-react-select .main-react-select__control {
    border-radius: 0 15px 15px 0;
    border-left: none; 
  }

  .location-react-select,
  .type-react-select {
    font-size: 0.8rem; 
  }

  .main-react-select__placeholder {
    font-size: 1.2rem; 
  }

  .search-button {
    margin-top: 1rem; 
    width: 30%;
    margin-left: 70%;
    font-size: 1.2rem;
    padding: 7px 15px;
  }

  .location-icon {
    width: 30px; 
    height: 30px; 
    background-color: white;
  }
  
}

@media (max-width: 1100px) and (min-width: 801px) {
  .search-group {
    flex-direction: column; 
    align-items: stretch; 
    width: 75%;
    gap: 0;
    margin-bottom: 50%;
    margin-top: 5%;
  }

  .search-button {
    margin-top: 1rem; 
    width: 30%;
    margin-left: 70%;
    font-size: 1.2rem;
    padding: 7px 15px;
  }
}

@media (max-width: 460px) {
  .main-react-select__placeholder {
    font-size: 0.8rem; 
  }
}