.company-saved-reviews-container {
  margin: auto;
  display: flex;
  max-width: 100%;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  margin-right: auto;
  margin-left: auto;
}

.company-saved-review-attachments {
  display: flex;
  align-items: center; 
  margin-top: 2.5px;
  margin-left: 25px;
  height: 100%; 
  flex-grow: 1;
  gap: 5px; 
}

.saved-attachment-wrapper {
  position: relative;
  display: inline-block; 
}

.company-saved-review-attachments__icon {
  width: 90px; 
  height: 90px; 
  object-fit: cover; 
  margin-right: 10px;
  border-radius: 10px;
}

@media (max-width: 1300px) {
  .company-saved-review-attachments__icon {
    width: 70px; 
    height: 90px; 
    object-fit: cover; 
    border-radius: 10px;
  }
}

@media (max-width: 1100px) {
  .company-saved-review-attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .company-saved-review-attachments__icon {
    width: 70px; 
    height: 40px; 
    max-height: 50px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 1000px) {
  .company-saved-review-attachments {
    margin-left: 30px;
  }

  .company-saved-review-attachments__icon {
    width: 60px; 
    height: 40px; 
    object-fit: cover; 
    border-radius: 10px;
  }
}

@media (max-width: 700px) {
  .company-saved-review-attachments__icon {
    width: 80px; 
    height: 90px; 
    object-fit: cover; 
    border-radius: 10px;
  }

  .company-saved-reviews-container {
    width: 100%;
    max-width: 100%;

  }
}

@media (max-width: 600px) { 
  .company-saved-review-attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .company-saved-review-attachments__icon {
    width: 40px; 
    height: 40px; 
    max-height: 50px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 550px) { 
  .company-saved-review-attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-right: 0px;
  }

  .company-saved-review-attachments__icon {
    width: 40px; 
    height: 40px; 
    max-height: 50px;
    object-fit: cover;
    border-radius: 10px;
  }

  .company-saved-review-attachments {
    margin-left: 30px;
  }
}

@media (max-width: 350px) { 
  .company-saved-review-attachments__icon {
    width: 0px; 
    height: 0px; 
  }
}
