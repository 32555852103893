.user-profile {
  background-color: #f1f0f0;
  justify-content: center;
}

.ei-user {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.background-picture {
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: 2;
  margin-right: 8%;
}

.background-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.user-white-background-container {
  position: relative;
  margin-right: 8%;
  top: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #b0b0b0;
  border-right: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
  padding-top: 14%;
}

.profile-picture {
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.profile-picture img {
  width: 13%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  margin-bottom: 1%;
  border: 3px solid #fff;
  z-index: 5;
  margin-left: 1%;
  margin-top: -6%;
}

.user-info-container {
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: #f0ecec;
  background: local;
  margin-left: 26%;
  margin-top: -13.6%;
}

.profile-user-name {
  font-size: 26px;
  margin-bottom: 0.4rem;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

.profile-user-real-name {
  font-size: 24px;
  margin-top: -12px;
  margin-bottom: 5%;
  color: #6b6b6b;
  font-family: 'Poppins', sans-serif;
}

.user-profile-buttons {
  top: 1px;
  right: 7px; 
  position: absolute; 
  display: flex;
  align-items: center;
  padding: 5px 5px;
  width: 85px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 3;
  gap: 12px;
}

.create-post-button {
  position: absolute; 
  display: flex;
  align-items: center;
  padding: 6px 8px;
  width: 75px;
  margin-right: 0px;
  border: 1px solid #b0b0b0;
  border-radius: 15px;
  cursor: pointer;
  z-index: 3;
}

.create-post-button img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.create-post-button:hover {
  border-width: 1.4px;
}

.user-profile-edit-profile {
  display: flex;
  position: absolute; 
  cursor: pointer;
  z-index: 3;
  left: -40px;
  top: 10px;
}

.user-profile-edit-profile img {
  width: 30px;
  height: 30px;
}

.user-profile-edit-profile:hover img {
  width: 32px;
  height: 32px;
}

.user-review-tabs {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
  background-color: white;
  border-radius: 0 0 15px 15px;
  z-index: 3;
  border-left: 1px solid #b0b0b0;
  border-right: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
  margin-right: 8%;
}

.user-review-tabs button {
  color: #045096;
  padding: 10px 60px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 0 0 15px 15px;
}

.user-review-tabs button:hover {
  background-color: #f1f0f0;
  color: #23313d;
}

.user-review-tabs button.active {
  background-color: #f1f0f0;
  font-weight: bold;
  color: #23313d;
}

@media (max-width: 1000px) {
  .ei-user {
    width: 100%;
    max-width: 100%;
  }

  .ex-user-white-background-container,
  .user-review-tabs,
  .user-white-background-container,
  .background-picture {
    margin-right: 0%;
  }

  .user-review-tabs {
    height: 45px;
  }

  .profile-picture img {
    width: 15%;
    height: auto;
  }

  .user-info-container {
    margin-left: 19%;
  }
}

@media (max-width: 630px) {
  .profile-user-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.4rem;
    font-family: 'Poppins', sans-serif;
  }

  .profile-user-real-name {
    font-size: 18px;
    margin-top: -12px;
    margin-bottom: 5%;
  }

  .user-review-tabs {
    height: 30px;
  }

  .user-review-tabs button {
    color: #045096;
    padding: 1px 0px;
    width: 100%;
    font-size: 0.6rem;
  }

  .user-profile-edit-profile img {
    width: 18px;
    height: 18px;
    margin-right: -100px;    
  }

  .user-profile-edit-profile:hover img {
    width: 19px;
    height: 19px;
  }

  .create-post-button {
    width: 50px;
    font-size: 12px;
    left: -15px;
    padding: 4px 8px;
  }
  
  .create-post-button img {
    width: 9px;
    height: 9px;
    margin-right: 6px;
  }
  
  .create-post-button:hover {
    border-width: 1.4px;
  }

  .user-profile-buttons {
    width: 40px;
  }
}

@media (max-width: 470px) {
  .profile-user-name {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0.4rem;
    font-family: 'Poppins', sans-serif;
  }

  .profile-user-real-name {
    font-size: 14px;
    margin-top: -12px;
    margin-bottom: 5%;
  }
}

@media (max-width: 400px) {
  .profile-user-name {
    font-size: 10px;
    font-weight: bold;
    margin-top: 0.2rem;
    font-family: 'Poppins', sans-serif;
  }

  .profile-user-real-name {
    font-size: 10px;
    margin-top: -8px;
    margin-bottom: 5%;
  }
}