.Header {
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #b0b0b0;
}

.profile-header-width {
  display: flex;
  justify-content: space-between;
  height: 55px;
  max-width: 60%;
  margin-right: 6%;
  width: 100%;
  align-items: center;
}

@media (max-width: 1000px) {
  .profile-header-width {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 630px) {
  .profile-header-width {
    height: 45px;
    max-width: 100%;
  }

}