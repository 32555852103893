.company-profile {
  background-color: #f1f0f0;
  justify-content: center;
}

.ei-company {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.background-picture-company {
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: 2;
  margin-right: 8%;
}

.background-picture-company img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.company-white-background-container {
  position: relative;
  margin-right: 8%;
  top: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border-left: 1px solid #b0b0b0;
  border-right: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
  padding-top: 14%;
}

.profile-picture-company {
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.profile-picture-company img {
  width: 13%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  margin-bottom: 1%;
  border: 3px solid #fff;
  z-index: 5;
  margin-left: 1%;
  margin-top: -6%;
}

.company-name-container {
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  background-color: #f0ecec;
  background: local;
  margin-left: 26%;
  margin-top: -13.1%;
}

.company-profile-username {
  font-size: 26px;
  margin-bottom: 0.4rem;
  font-family: 'Poppins', sans-serif;
  color: #000000;
}

.company-profile_name {
  font-size: 24px;
  margin-top: -12px;
  margin-bottom: 5%;
  color: #6b6b6b;
  font-family: 'Poppins', sans-serif;
}

.company-info {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 95%;
  background-color: transparent;
  padding: 20px;
  margin-top: -3%;
}

.company-info-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  font-size: 1.2rem;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  align-content: center;
}

.price-range-info {
  margin-top: 10px;
  margin-bottom: -10px;
}

.specialties-container, 
.contact-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.location-info,
.website-info,
.contact-info {
  margin-top: 10px;
  align-items: left;
}

.info-header {
  font-weight: 200;
  color: black;
  display: flex;
  align-items: left;
  gap: 10px;
  font-size: 20px;
}

.info-header img {
  height: 20px;
  width: auto;
  margin-top: 6px;
}

.text-container {
  font-size: 17px;
  color: #454545;
}

.launch-icon {
  height: 12px;
  width: auto;
  margin-left: 4px;
}

.specialty-header {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: -15px;
  font-size: 20px;
}

.specialty-header img {
  height: 22px;
  width: auto;
}

.specialties-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 30px;
}

.specialties-columns {
  display: flex;
  width: 100%;
  font-size: 17px;
  color: #454545;
}

.specialties-column {
  flex: 1;
  padding: 0 10px;
  box-sizing: border-box;
}

.specialty-column ul {
  padding-left: 20px;
  padding-right: 40px;
}

.text-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-container.small {
  font-size: 0.9rem; 
}

.company-bio {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 5%;
  width: 100%;
  margin-bottom: 0px;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.internal-company-buttons {
  top: 1px;
  right: 0px; 
  margin-right: 30px;
  position: absolute; 
  display: flex;
  align-items: center;
  width: 125px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 3;
  gap: 12px;
}

.company-create-post-button {
  position: absolute; 
  display: flex;
  align-items: center;
  padding: 6px 8px;
  width: 90px;
  margin-top: 50px;
  margin-left: 42px;
  border: 1px solid #b0b0b0;
  border-radius: 15px;
  cursor: pointer;
  z-index: 3;
}

.company-create-post-button img {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.company-create-post-button:hover {
  border-width: 1.4px;
}

.company-edit-profile {
  display: flex;
  position: absolute; 
  cursor: pointer;
  z-index: 3;
  left: -4px;
  top: 10px;
}

.company-edit-profile img {
  width: 30px;
  height: 30px;
}

.company-edit-profile:hover img {
  width: 32px;
  height: 32px;
}

.company-review-tabs {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 100%;
  background-color: white;
  border-radius: 0 0 15px 15px;
  z-index: 3;
  border-left: 1px solid #b0b0b0;
  border-right: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
  margin-right: 8%;
}

.company-review-tabs button {
  color: #045096;
  padding: 10px 60px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 0 0 15px 15px;
}

.company-review-tabs button:hover {
  background-color: #f1f0f0;
  color: #23313d;
}

.company-review-tabs button.active {
  background-color: #f1f0f0;
  font-weight: bold;
  color: #23313d;
}

@media (max-width: 1000px) {
  .ei-company {
    width: 100%;
    max-width: 100%;
  }

  .company-review-tabs,
  .background-picture-company,
  .company-white-background-container {
    margin-right: 0%;
  }

  .company-review-tabs {
    height: 45px;
  }

  .profile-picture-company img {
    width: 15%;
    height: auto;
  }

  .company-name-container {
    margin-left: 19%;
  }

  .company-info {
    width: 100%;
  }

  .company-info-container {
    gap: 15px;
    font-size: 1rem;
  }

  .info-header img {
    height: 20px;
  }

  .text-container {
    font-size: 0.9rem;
  }

  .specialty-header img {
    height: 20px;
  }

  .specialty-column ul {
    padding-left: 15px;
    padding-right: 30px;
  }

  .company-bio {
    width: 90%;
  }
}

@media (max-width: 630px) {
  .company-profile-username {
    font-size: 19px;
    margin-bottom: 0.4rem;
    font-family: 'Poppins', sans-serif;
  }

  .company-profile_name {
    font-size: 18px;
    margin-top: -12px;
    margin-bottom: 5%;
  }

  .company-review-tabs {
    height: 30px;
  }

  .company-review-tabs button {
    width: 100%;
    font-size: 0.6rem;
  }

  .company-edit-profile img {
    width: 18px;
    height: 18px;
    margin-right: -100px;    
    margin-left: -14px;
  }

  .company-edit-profile:hover img {
    width: 19px;
    height: 19px;
  }

  .company-create-post-button {
    width: 50px;
    font-size: 10px;
    padding: 4px 8px;
    margin-left: 10px;
    margin-top: 35px;
  }
  
  .company-create-post-button img {
    width: 9px;
    height: 9px;
    margin-right: 6px;
  }
  
  .company-create-post-button:hover {
    border-width: 1.4px;
  }

  .internal-company-buttons {
    width: 65px;
  }

  .internal-calendar:hover {
    width: 19px;
    height: 19px;
  }

  .company-info-container {
    gap: 10px;
    font-size: 0.8rem;
  }

  .info-header {
    font-size: 14px;
    gap: 3px;
  }

  .info-header img {
    height: 15px;
    margin-top: 4px;
  }

  .text-container {
    font-size: 12px;
  }

  .specialty-header img {
    height: 15px;
  }

  .specialty-header {
    font-size: 14px;
  }
  
  .specialties-columns {
    display: flex;
    width: 100%;
    font-size: 12px;
  }

  .company-bio {
    font-size: 12px;
  }
  
}

@media (max-width: 470px) {
  .company-profile-username {
    font-size: 15px;
    margin-bottom: 0.4rem;
    font-family: 'Poppins', sans-serif;
  }

  .company-profile_name {
    font-size: 14px;
    margin-top: -12px;
    margin-bottom: 5%;
  }

  .company-info-container {
    gap: 10px;
    font-size: 0.8rem;
  }

  .info-header {
    font-size: 11px;
    gap: 3px;
  }

  .info-header img {
    height: 12px;
    margin-top: 3px;
  }

  .text-container {
    font-size: 9px;
  }

  .specialty-header img {
    height: 12px;
  }

  .specialty-header {
    font-size: 11px;
    margin-bottom: -9px;
  }
  
  .specialties-columns {
    font-size: 9px;
  }

  .company-bio {
    font-size: 9px;
  }
}

@media (max-width: 400px) {
  .company-profile-username {
    font-size: 11px;
    margin-top: 0.2rem;
    font-family: 'Poppins', sans-serif;
  }

  .company-profile_name {
    font-size: 10px;
    margin-top: -8px;
    margin-bottom: 5%;
  }

  .company-info-container {
    gap: 10px;
    font-size: 0.6rem;
  }

  .info-header {
    font-size: 9px;
    gap: 3px;
  }

  .info-header img {
    height: 12px;
    margin-top: 3px;
  }

  .text-container {
    font-size: 6px;
  }

  .specialty-header img {
    height: 12px;
  }

  .specialty-header {
    font-size: 9px;
    margin-bottom: -9px;
  }
  
  .specialties-columns {
    font-size: 6px;
  }

  .company-bio {
    font-size: 6px;
  }
}