.invoice-zoom-full-page {
  background-color: white;
}

.invoice-zoom-container {
  background-color: #f1f1f1;
}

.zoom-current-invoice-cancel-button {
  --primary-color: #d9534f;
  --hovered-color: #c9302c;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  gap: 0.5rem;
  align-items: center;
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 50px;
  color: var(--primary-color);
  transition: all 0.3s ease;
  align-self: flex-end;
  width: 35%;
}

.zoom-current-invoice-cancel-button p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--primary-color);
}

.zoom-current-invoice-cancel-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.zoom-current-invoice-cancel-button p::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.zoom-current-invoice-cancel-button:hover::after,
.zoom-current-invoice-cancel-button.active::after {
  width: 100%;
}

.zoom-current-invoice-cancel-button:hover p::before,
.zoom-current-invoice-cancel-button.active p::before {
  width: 100%;
}

.zoom-current-invoice-cancel-button:hover svg,
.zoom-current-invoice-cancel-button.active svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.zoom-current-invoice-cancel-button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.zoom-current-invoice-cancel-button.active {
  --primary-color: var(--hovered-color);
}