.edit-highlight-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-highlight-modal-content {
  background: #f1f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50%;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.edit-highlight-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  background: transparent;
  color: #333;
}

.edit-highlight-form-header {
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
}

.edit-highlight-form-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: 'Poppins', sans-serif;
}

.edit-highlight-input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-sizing: border-box;
}

.edit-highlight-textarea {
  width: 100%;
  height: 160px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-sizing: border-box;
}

.edit-highlight-file-input-label {
  display: inline-block;
  background-color: #045096;
  color: white;
  padding: 8px 10px;
  font-family: 'Inter-SemiBoldItalic', sans-serif;
  font-size: 0.7rem;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  text-align: left;
  width: 20%;
  transition: background-color 0.3s;
}

.edit-highlight-file-input-label:hover {
  background-color: #23313d;
}

.file-input-hidden {
  display: none;
}

.edit-highlight-file-name-display {
  font-size: 0.9rem;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.edit-highlight-file-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.edit-highlight-file-item span {
  margin-right: 10px;
}

.edit-highlight-remove-button {
  background: none;
  border: none;
  color: #d9534f;
  font-size: 0.8rem;
  cursor: pointer;
}

.edit-highlight-remove-button:hover {
  color: #c9302c;
}

.edit-highlight-post-button {
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  width: 80%;
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 8px;
  transition: all 0.3s ease;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.edit-highlight-post-button:hover {
  border-color: #23313d;
  color: #23313d;
  background-color: transparent;
}

.edit-highlight-textarea::-webkit-scrollbar,
.edit-highlight-review-form textarea::-webkit-scrollbar,
.edit-highlight-modal-content::-webkit-scrollbar {
  width: 8px;
}

.edit-highlight-textarea::-webkit-scrollbar-thumb,
.edit-highlight-review-form textarea::-webkit-scrollbar-thumb,
.edit-highlight-modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.edit-highlight-textarea::-webkit-scrollbar-thumb:hover,
.edit-highlight-review-form textarea::-webkit-scrollbar-thumb:hover,
.edit-highlight-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.edit-highlight-textarea::-webkit-scrollbar-track,
.edit-highlight-review-form textarea::-webkit-scrollbar-track,
.edit-highlight-modal-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .edit-highlight-modal-content {
    width: 90%;
  }

  .file-input-label,
  .edit-highlight-post-button {
    width: 100%;
  }
}
