.image-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9990;
}

.image-modal-content {
  position: relative;
  width: 80%;
  height: 80%;
  background: rgba(120, 120, 120, 0.7);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal-enlarged {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  z-index: 0;
}

.image-modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 43px;
  border: none;
  font-size: 2.3rem;
  cursor: pointer;
  color: black;
  background-color: transparent;
  z-index: 9999;
}

.image-modal-navigation-button {
  position: absolute;
  border: 2px solid black;
  background-color: transparent;
  padding: 12px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-size: 2.2rem;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-modal-prev-button {
  left: 20px;
}

.image-modal-next-button {
  right: 20px;
}
