.about-us-header {
  position: relative;
  text-align: center;
  margin-bottom: 50px;
}

.about-us-banner {
  width: 100%;
  height: auto;
  filter: brightness(40%);
  z-index: 1;
}

.about-us-title-banner {
  width: 100%;
  background-color: none;
  border-bottom: 1px solid #ccc;
  position: absolute;
  top: 78px;
  z-index: 2;
}

.about-us-title {
  font-size: 2.3rem;
  font-family: 'Montserrat', sans-serif;
  color: white;
  position: absolute;
  left: 5%;
  top: -60px;
  bottom: 10px;
  z-index: 3;
  text-align: left;
}

.about-us-subtitle {
  font-size: 1.7em;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.about-us-transition {
  padding: 50px 0;
  text-align: center;
}

.transition-effect {
  width: 80%;
  height: 3px;
  background: #ccc;
  margin: 0 auto;
}

.about-us-content {
  max-width: 70%;
  margin: 0 auto;
  padding: 50px 0;
  text-align: left;
}

.about-us-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.about-us-section.reverse {
  flex-direction: row-reverse;
}

.about-image-container {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-image {
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.about-social-icons {
  display: flex;
  gap: 10px;
  margin-top: 7px;
  margin-bottom: 5px;
}

.about-social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.about-social-icon img {
  width: 70%;
  height: 70%;
  object-fit: cover;
  filter: grayscale(100%) brightness(0) invert(0); 
  transition: filter 0.3s ease; 
}

.about-social-icon.instagram {
  color: #e1306c;
}

.about-social-icon.instagram:hover {
  background-color: #e1306c;
  color: white; 
}

.about-social-icon.instagram:hover img {
  filter: grayscale(100%) brightness(0) invert(1); 
}

.about-social-icon.linkedin {
  color: #0e76a8;
}

.about-social-icon.linkedin:hover {
  background-color: #0e76a8;
  color: white; 
}

.about-social-icon.linkedin:hover img {
  filter: grayscale(100%) brightness(0) invert(1); 
}

.about-social-icon.tiktok {
  color: #69c9d0; 
}

.about-social-icon.tiktok:hover {
  background-color: #69c9d0;
  color: white; 
}

.about-social-icon.tiktok:hover img {
  filter: grayscale(100%) brightness(0) invert(1);
}

.about-social-icon.spotify {
  color: #1db954;
}

.about-social-icon.spotify:hover {
  background-color: #1db954; 
  color: white; 
}

.about-social-icon.spotify:hover img {
  filter: grayscale(100%) brightness(0) invert(1); 
}

.about-us-text {
  width: 45%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.card-name {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: -20px;
}

.card-title {
  font-size: 1.1em;
  color: #777;
  padding: 10px 0px;
  margin-bottom: -10px;
  border-bottom: 2px solid #ccc;
}

.card-description {
  font-size: 1.1em;
  line-height: 1.5;
  margin-bottom: -12px;
}

.card-details {
  display: flex;
  justify-content: space-between;
}

.card-column {
  width: 45%;
}

.card-column h4 {
  font-size: 1.2em;
  margin-bottom: 0px;
}

.card-column ul {
  list-style-type: none;
  padding: 0;
}

.card-column ul li {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 5px;
}

.our-story-section {
  display: flex;
  align-items: flex-start;
  padding: 50px 0;
  max-width: 60%;
  margin: 0 auto;
  text-align: left;
}

.our-story-title {
  font-size: 2.8rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin-right: 15%;
}

.our-story-paragraph {
  font-size: 1.2em;
  line-height: 1.5;
  flex: 1;
  max-width: 70%;
  margin-top: 50px;
}

.join-us-section {
  text-align: center;
  padding: 50px 0;
}

.join-us-title {
  font-size: 2.4rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}

.join-us-paragraph {
  font-size: 1.2em;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 50px;
}

.about-button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background-color: #045096;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffff;
  gap: 0px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 20px;
  padding: 15px 30px;
  margin: 0 auto; 
  margin-bottom: 120px;
}

.about-button:hover {
  transform: scale(1.05);
  border-color: #fff9;
  background-color: #045096; 
  color: #ffff;
}

.about-button:hover .icon {
  transform: translate(4px);
}

.about-button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.about-button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

@media (max-width: 750px) {
  .about-us-content {
    padding: 20px;
    max-width: 100%;
  }

  .about-us-section {
    flex-direction: column;
  }

  .about-us-section.reverse {
    flex-direction: column;
  }

  .about-us-image, .about-us-text {
    width: 90%;
    margin-bottom: 20px;
  }

  .about-image-container {
    width: 75%;
  }

  .about-us-text h2 {
    font-size: 1.5em;
  }

  .about-us-text p {
    font-size: 1em;
  }

  .our-story-section {
    flex-direction: column;
    align-items: center;
  }

  .our-story-title {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media (max-width: 550px) {
  .about-us-subtitle {
    font-size: 1.4em;
  }

}

@media (max-width: 450px) {
  .about-us-header {
    margin-bottom: 0px;
  }

  .about-us-image, .about-us-text {
    width: 90%;
    margin-bottom: 0px;
  }

  .about-us-title-banner {
    top: 48px;
  }

  .about-us-title {
    font-size: 1.6rem;
    top: -40px;
  }

  .about-us-subtitle {
    font-size: 1.2em;
    top: 60%;
  }

  .about-image-container {
    width: 100%;
    height: 100%;
  }

  .about-us-image {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  .about-us-text {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .about-us-text h2 {
    font-size: 1.2em;
  }

  .about-us-text p {
    font-size: 0.8em;
  }

  .our-story-section {
    max-width: 100%;
    padding: 20px;
  }

  .our-story-title {
    font-size: 2.2rem;
  }

  .our-story-paragraph {
    font-size: 1em;
    width: 80%;
  }

  .join-us-section {
    text-align: center;
    padding: 20px 50px;
  }
  
  .join-us-title {
    font-size: 1.9rem;
  }
  
  .join-us-paragraph {
    font-size: 1em;
    align-items: center;
  }
  
  .about-button {
    font-size: 17px;
  }
}