.calendar-container {
  background-color: #f1f0f0;
}

.day-view {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-bottom: 20px;
}

.day-view-header {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  background-color: #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
}

.timeline-slot {
  position: relative;
  height: 60px;
  border-bottom: 1px solid #eee;
}

.timeline-time {
  position: absolute;
  top: 2px;
  left: 0;
  width: 60px;
  text-align: right;
  padding-right: 10px;
  font-size: 12px;
  color: #888;
}

.timeline-entries {
  position: absolute;
  top: 7px;
  left: 70px;
  right: 10px;
  height: 100%;
}

.timeline-entry {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(200, 200, 200, 0.3);
  border-left: 3px solid #c84747;
  padding: 5px;
  box-sizing: border-box;
  font-size: 12px;
  margin-top: -6px;
  overflow: hidden;
}

.entry-time {
  font-weight: bold;
}

.calendar-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.calendar-modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  position: relative;
  max-height: 70vh;
  overflow-y: auto;
}

.modal-header-request-time {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.modal-header-request-time {
  --primary-color: #111;
  --hovered-color: #045096;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  gap: 0.5rem;
  align-items: center;
  margin: 0 10px;
  padding: 0px 10px;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 15%;
  top: 5px;
}

.modal-header-request-time p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--primary-color);
}

.modal-header-request-time::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.modal-header-request-time p::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.modal-header-request-time:hover::after,
.modal-header-request-time.active::after {
  width: 100%;
}

.modal-header-request-time:hover p::before,
.modal-header-request-time.active p::before {
  width: 100%;
}

.modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.modal-unavailable-indicator {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  margin-right: 10px;
}

.calendar-modal-content::-webkit-scrollbar {
  width: 8px;
}

.calendar-modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.calendar-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.calendar-modal-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.request-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.request-modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  position: relative;
  max-height: 70vh;
  overflow-y: auto;
}

.request-modal-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.request-modal-content input[type="time"],
.request-modal-content input[type="number"],
.request-modal-content input[type="text"],
.request-modal-content textarea {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 15px;
}

.request-modal-content span {
  text-align: left;
}

.close-modal-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  font-size: 2rem;
  cursor: pointer;
  color: #000;
  border: none;
}

.submit-request-modal-button {
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 7px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.submit-request-modal-button:hover {
  border-color: #23313d;
  color: #23313d;
}

.request-label {
  text-align: left;
  width: 95%;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
}

.request-input {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 15px;
}

.error-message {
  color: red;
  font-size: 1rem;
  margin-bottom: 10px;
}

.success-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9990;
}

.success-message {
  color: green;
  font-size: 1.4rem;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: rgb(211, 211, 211);
  padding: 20px;
  border-radius: 10px;
  outline: none;
}
