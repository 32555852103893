.post-review {
  background-color: white;
}

.post-review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 150px;
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  max-width: 70%;
  margin: 50px auto;
  border: 1px solid #e3e3e3;
}

.form-header {
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  margin-top: -20px;
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.7rem;
}

.review-form {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  width: 100%;
}

.rating-container {
  display: grid;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 0.5rem;
  grid-template-columns: max-content auto;
}

.form-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.review-rating-label {
  font-size: 1.3rem;
  justify-self: start;
  font-family: 'Poppins', sans-serif;
}

.ratings {
  display: flex;
  align-items: center;
  width: auto;
  font-size: 2rem;
}

.rating-category {
  display: flex;
  margin-top: 0.5rem;
}

.star {
  font-size: 2.3rem;
  margin-bottom: 7px;
  color: #ccc;
  cursor: pointer;
  transition: color 0.3s;
}

.star.active,
.star:hover {
  color: #ffcc00;
}

.review-form input[type='text'],
.review-form textarea {
  width: 100%; 
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.review-form textarea {
  height: 100px;
}

.company-user-input {
  width: 0%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.075);
}

.file-input-label {
  display: inline-block;
  background-color: #045096;
  color: white;
  padding: 10px 15px;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  text-align: left;
  width: 35%;
  transition: background-color 0.3s;
  margin-top: 0px;
  margin-bottom: 30px;
}

.file-input-label:hover {
  background-color: #23313d;
}

.file-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.file-name-display {
  font-size: 1rem;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
}

.file-item span {
  margin-right: 10px;
}

.remove-button {
  background: none;
  border: none;
  color: #d9534f;
  font-size: 0.8rem;
  cursor: pointer;
  position: absolute;
  right: -5px;
  top: -3px;
}

.remove-button:hover {
  color: #c9302c;
}

.error-message {
  color: #d9534f;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 7px;
}

.post-button {
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem; 
  margin-top: 20px; 
  width: 80%;
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 10px 40px;
  transition: all 0.3s ease;
  align-self: center;
  font-family: 'Raleway', sans-serif;
}

.post-button:hover {
  border-color: #23313d;
  color: #23313d;
  background-color: transparent;
}

@media (max-width: 1000px) {
  .review-form {
    width: 80%;
  }

  .form-header {
    font-size: 2rem;
  }

  .post-review-container {
    margin-top: 10px;
    margin-bottom: 100px;
    max-width: 90%;
  }

  .file-input-label {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .star {
    font-size: 1.8rem;
  }
}

@media (max-width: 600px) {
  .post-review-container {
    padding: 10px;
  }

  .form-header {
    font-size: 1.5rem;
  }

  .file-input-label {
    width: 50%;
  }

  .review-form textarea {
    height: 100px;
  }

  .file-item span {
    margin-right: 0px; 
  }

  .review-form input[type='text'],
  .review-form textarea {
    font-size: 0.8rem;
  }

  .file-input-label {
    font-size: 0.7rem;
  }

  .file-name-display {
    font-size: 0.7rem;
  }
  
  .post-button {
    font-size: 1.1rem; 
    width: 60%;
    padding: 6px 0px;
  }

  .star {
    font-size: 1.4rem;
  }
}


@media (max-width: 520px) {
  .star {
    font-size: 1.2rem;
    margin-top: 6px;
  }

  .review-rating-label {
    font-size: 0.9rem;
  }
}

@media (max-width: 440px) {
  .star {
    font-size: 1rem;
    margin-top: 2px;
  }

  .review-rating-label {
    font-size: 0.75rem;
  }
}

@media (max-width: 385px) {
  .star {
    font-size: 0.8rem;
    margin-top: 2px;
  }

  .review-rating-label {
    font-size: 0.6rem;
  }
}