.reset-password {
  background-color: white;
}

.forgot-password {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 15px;
  max-width: 70%; 
  margin: 50px auto; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border: 1px solid #e3e3e3; 
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}

.forgot-password-container {
  width: 70%;
}
  
.forgot-password h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 2rem;
  display: block;
  text-align: center;
}
  
.forgot-password form label {
  display: block;
  margin-bottom: 10px;
  color: #333;
  font-size: 1.5rem;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}
  
.forgot-password form input,
.forgot-password form textarea {
  width: calc(100% - 20px); 
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}
  
.forgot-password form button {
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: 60%;
  margin-top: 20px;
  border: 2px solid #045096;
  background-color: white;
  color: #045096;
  padding: 10px 40px;
  transition: all 0.3s ease;
  align-self: center;
  font-family: 'Raleway', sans-serif
}
  
.forgot-password form button:hover {
  border-color: #23313d;
  color: #23313d;
}

.valid {
  background-image: url('/public/icons/check.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 25px 25px;
  background-color: #e8f5e9;
  padding-right: 40px; 
}

.invalid {
  background-color: #ffebee;
}
  
.error {
  color: #d9534f;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 7px;
}
  
.message {
  color: #008000;
  margin-bottom: 15px;
}
  
@media (max-width: 768px) {
  .forgot-password {
    padding: 20px;
  }
  
  .forgot-password h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .forgot-password form button {
    padding: 15px 10px;
  }
}  