.filter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.filter-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 80%;
}

.filter-header, .filter-footer {
  position: sticky;
  background: #fff;
  padding: 10px 0;
  z-index: 10;
}

.filter-header {
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.filter-header h2 {
  margin: 0;
}

.filter-header .close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.filter-footer {
  bottom: 0;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
}

.filter-body {
  overflow-y: auto;
  padding: 20px 0;
}

.filter-group {
  margin-bottom: 20px;
  width: 90%;
  text-align: left;
  margin-left: 10px;
}

.filter-group label {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 17px;
}

.price-filter-group {
  margin-bottom: 40px;
  width: 81%;
  margin-left: 20px;
  margin-top: 15px;
}

.price-filter-group label {
  display: block;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 13px;
  text-align: left;
}

.ratings-filter-group {
  margin-bottom: 20px;
  margin-left: 3%;
  width: 88%;
  margin-top: 15px;
  align-content: center;
}

.ratings-filter-group label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 13px;
  text-align: left;
}

.radius-slider,
.price-slider,
.rc-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  margin: 0 0 10px 0;
}

.radius-slider::-webkit-slider-thumb,
.price-slider::-webkit-slider-thumb,
.rc-slider-handle {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  background: #fff;
  cursor: grab;
  border-radius: 50%;
}

.radius-slider::-moz-range-thumb,
.price-slider::-moz-range-thumb,
.rc-slider-handle {
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  background: #fff;
  cursor: grab;
  border-radius: 50%;
}

.radius-value,
.price-value,
.rating-value {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.specialties-group label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.specialties-list {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.specialty-item {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.specialty-item input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #333;
  background: #fff;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
}

.specialty-item input[type="checkbox"]:checked::before {
  content: '';
  width: 10px;
  height: 10px;
  background: #333;
  position: absolute;
  top: 2px;
  left: 2px;
}

.specialty-item label {
  font-size: 16px;
  cursor: pointer;
  font-weight: normal;
  margin-top: 8px;
}

.reset-button,
.apply-button {
  background: #23313d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reset-button:hover,
.apply-button:hover {
  background: #333a41;
}

.filter-body::-webkit-scrollbar {
  width: 8px;
}

.filter-body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.filter-body::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.filter-body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}
