.blocked-container {
  background-color: #f9f9f9;
  width: 90%;
}

.blocked-header {
  font-weight: bold;
  display: block; 
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.7rem;
  margin-bottom: 1.5rem;
}

.blocked-list {
  margin-top: 30px;
}

.blocked-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e3e3e3;
}

.entity-info {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.entity-profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.entity-details {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.entity-name {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  margin-bottom: 3px;
}

.entity-username {
  font-size: 14px;
  color: #999;
}

.unblock-button {
  background-color: #f44336;
  padding: 8px 15px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.unblock-button:hover {
  background-color: #d32f2f;
}
