.profile-invoice {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 20px;
}

.profile-invoice-header {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
}

.user-invoice-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-invoice-profile img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.user-name {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.payment-methods {
  display: flex;
  flex-direction: column;
}

.payment-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.payment-method.selected {
  background-color: #e0f7fa;
}

.method-info {
  font-size: 16px;
  color: #555;
}

.method-expiry {
  font-size: 14px;
  color: #555;
  margin-left: auto;
  margin-right: 10px;
}

.method-expiry.expired {
  color: red;
}

.delete-button-wrapper {
  position: absolute;
  top: -12px;
  right: -10px;
}

.delete-button {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.delete-card-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-card-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 350px;
  max-width: 90%;
  box-sizing: border-box;
  position: relative;
}

.delete-card-modal-actions {
  display: flex;
  justify-content: center; 
  margin-top: 40px;
  gap: 10px; 
  margin-bottom: -10px;
}

.delete-card-yes-button {
  color: #fb3e3e;
  padding: 10px 35px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.delete-card-yes-button:hover {
  background-color: #f1f0f0;
  color: #ff0000;
}

.delete-card-no-button {
  color: #045096;
  padding: 10px 35px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.delete-card-no-button:hover {
  background-color: #f1f0f0;
  color: #23313d;
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.profile-invoice-no-payent {
  font-size: 22px;
  color: #6f6d6d;
  margin-bottom: 10px;
  margin-top: 10px;
}

.add-payment-button {
  --primary-color: #045096;
  --hovered-color: #23313d;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  gap: 0.5rem;
  align-items: center;
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--primary-color);
  transition: all 0.3s ease;
  border-radius: 5px;
}

.add-payment-button p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--primary-color);
}

.add-payment-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.add-payment-button p::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.add-payment-button:hover::after,
.add-payment-button.active::after {
  width: 100%;
}

.add-payment-button:hover p::before,
.add-payment-button.active p::before {
  width: 100%;
}

.add-payment-button:hover svg,
.add-payment-button.active svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.add-payment-button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.add-payment-button.active {
  --primary-color: var(--hovered-color);
}


@media (max-width: 1000px) and (min-width: 651px) {
  .user-name {
    font-size: 17px;
  }

  .user-invoice-profile img {
    width: 70px;
    height: 70px;
  }

  .profile-invoice-no-payent {
    font-size: 18px;
  }

  .add-payment-button {
    padding: 6px 35px;
  }
}

@media (max-width: 700px) and (min-width: 651px) {
  .user-name {
    font-size: 14px;
  }

  .user-invoice-profile img {
    width: 60px;
    height: 60px;
  }

  .profile-invoice-no-payent {
    font-size: 13px;
  }

  .add-payment-button {
    padding: 4px 15px;
  }
}

@media (max-width: 450px) {
  .user-name {
    font-size: 17px;
  }

  .user-invoice-profile img {
    width: 70px;
    height: 70px;
  }

  .profile-invoice-no-payent {
    font-size: 18px;
  }

  .add-payment-button {
    padding: 6px 35px;
  }
}

@media (max-width: 350px) {
  .user-name {
    font-size: 15px;
  }

  .user-invoice-profile img {
    width: 60px;
    height: 60px;
  }

  .profile-invoice-no-payent {
    font-size: 14px;
  }

  .add-payment-button {
    padding: 6px 25px;
    font-size: 0.7rem;
  }
}