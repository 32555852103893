.calendar-container {
  background-color: #f1f0f0;
}

.schedule {
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 150px;
  width: 80%;
  max-width: 1200px;
}

.profile-header {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: white;
  border-radius: 15px;
}

.profile-header img {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.profile-header .company-name {
  font-size: 20px;
  font-weight: bold;
}

.calendar {
  width: 100%;
  margin: 0 auto;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.calendar-header button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.calendar-header span {
  font-size: 18px;
  cursor: pointer;
}

.month-picker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.month-picker-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
}

.month-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.month-picker-header button {
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.2rem;
}

.month-picker-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.month-picker-item {
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}

.month-picker-item:hover {
  background: #e0e0e0;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  z-index: 200;
}

.calendar-day {
  padding: 10px;
  position: relative;
  border: 1px solid #ccc;
  height: 100px;
  box-sizing: border-box;
  z-index: 200;
  cursor: pointer;
}

.calendar-day.empty {
  background: #f9f9f9;
}

.day-number {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 14px;
}

.add-entry-btn {
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 300;
}

.calendar-day:hover .add-entry-btn {
  display: block;
}

.calendar-day.unavailable {
  background: rgba(0, 0, 0, 0.1);
}

.calendar-day.partial-unavailable {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 10px,
    transparent 10px,
    transparent 20px
  );
  font-size: 0.5rem;
}

.availability-details {
  position: absolute;
  bottom: 25px;
  left: 5px;
  right: 5px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.availability-time {
  font-size: 10px;
  white-space: nowrap;
  display: inline-block;
}

.reminder-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.requested-block {
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(0, 128, 0, 0.3);
}

.calendar-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.calendar-modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  position: relative;
}

.calendar-modal-content label {
  display: block;
  font-size: 1rem;
  color: #333;
  text-align: left;
  margin-left: 16%;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}

.calendar-input-container {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}

.sched-input-error {
  border: 2px solid #d3211b;
}

.sched-save-error {
  color: red;
  margin-top: 10px;
  font-size: 0.9rem;
  text-align: center;
}

.calendar-modal-content input[type="text"],
.calendar-modal-content input[type="time"],
.calendar-modal-content input[type="date"] {
  width: calc(70% - 30px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.calendar-modal-content input[type="checkbox"] {
  margin-left: 15px;
  align-self: left;
  margin-top: 1px;
  cursor: pointer;
}

.calendar-modal-content textarea {
  width: calc(70% - 30px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.ui-checkbox {
  --primary-color: #045096;
  --secondary-color: #fff;
  --primary-hover-color: #4096ff;
  --checkbox-diameter: 20px;
  --checkbox-border-radius: 5px;
  --checkbox-border-color: #d9d9d9;
  --checkbox-border-width: 1px;
  --checkbox-border-style: solid;
  --checkmark-size: 1.2;
}

.ui-checkbox, 
.ui-checkbox *, 
.ui-checkbox *::before, 
.ui-checkbox *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ui-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(--checkbox-diameter);
  height: var(--checkbox-diameter);
  border-radius: var(--checkbox-border-radius);
  background: var(--secondary-color);
  border: var(--checkbox-border-width) var(--checkbox-border-style) var(--checkbox-border-color);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}

.ui-checkbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
  box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
  border-radius: inherit;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ui-checkbox::before {
  top: 40%;
  left: 50%;
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  border-right: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
  -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),opacity 0.1s;
}

.ui-checkbox:hover {
  border-color: var(--primary-color);
}

.ui-checkbox:checked {
  background: var(--primary-color);
  border-color: transparent;
}

.ui-checkbox:checked::before {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  -ms-transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ui-checkbox:active:not(:checked)::after {
  -webkit-transition: none;
  -o-transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: none;
  opacity: 1;
}

.calendar-save-button {
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 7px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: 40%;
  transition: all 0.3s ease;
  margin-top: 20px;
  margin-bottom: 50px;
}

.calendar-save-button:hover {
  border-color: #23313d;
  color: #23313d;
}

.calendar-close-modal-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  font-size: 2.0rem;
  cursor: pointer;
  color: #000;
  border: none;
}

.calendar-delete-button {
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  color: #000;
}

.error-message {
  color: red;
  margin-top: 20px;
  font-size: 1rem;
}

.calendar-day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background: #f9f9f9;
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
}

.unavailable-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
  font-weight: bold;
}

.request-item {
  text-align: left;
  margin-bottom: 40px;
}

.request-item a {
  text-decoration: none;
  color: #111; 
  font-size: 1.2rem;
  font-weight: bold;
}

.request-item a:hover {
  color: #555;
}

.entry-user-button a {
  text-decoration: none;
  color: #333; 
  font-size: 0.9rem;
  font-weight: bold;
}

.entry-user-button a:hover {
  color: #555;
}

.request-item p {
  text-align: left;
  margin-bottom: -20px;
  font-size: 1.3rem;
}

.view-schedule-button {
  margin-right: 10px;
  margin-left: -10px;
  padding: 5px 10px;
  background-color: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
  margin-top: 35px;
  font-weight: 600;
  font-size: 16px;
}

.view-schedule-button:hover {
  color: #045096;
}

.confirm-button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
  margin-top: 30px;
  font-weight: 600;
  font-size: 16px;
}

.confirm-button:hover {
  color: green;
}

.decline-button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: transparent;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
  margin-top: 30px;
  font-weight: 600;
  font-size: 16px;
}

.decline-button:hover {
  color: rgb(231, 15, 15);
}

.request-modal-content::-webkit-scrollbar {
  width: 8px;
}

.request-modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.request-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.request-modal-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.daily-schedule-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.request-date-label {
  font-size: 1.4rem;
}

.daily-schedule-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  position: relative;
  max-height: 70vh; 
  overflow-y: auto; 
}

.daily-schedule-content::-webkit-scrollbar {
  width: 8px;
}

.daily-schedule-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.daily-schedule-content::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.daily-schedule-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.view-buttons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.view-button {
  --primary-color: #111;
  --hovered-color: #045096;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  gap: 0.5rem;
  align-items: center;
  margin: 0 10px;
  padding: 0px 10px;
  border: none;
  background: none;
  cursor: pointer;
}

.view-button p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--primary-color);
}

.view-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.view-button p::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.view-button:hover::after,
.view-button.active::after {
  width: 100%;
}

.view-button:hover p::before,
.view-button.active p::before {
  width: 100%;
}

.view-button:hover svg,
.view-button.active svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.view-button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.view-button.active {
  --primary-color: var(--hovered-color);
}

.week-view {
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.week-day {
  flex: 1;
  border-left: 1px solid #ccc;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.week-day:first-child {
  border-left: none;
}

.week-day-header {
  text-align: center;
  font-weight: bold;
  padding: 5px;
  background-color: #f0f0f0;
}

.week-timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(60px * 18);
}

.week-timeline-slot {
  position: relative;
  height: 60px;
  border-bottom: 1px solid #eee;
}

.week-timeline-time {
  position: absolute;
  top: 7px;
  left: 0;
  width: 50px;
  margin-left: -10px;
  text-align: right;
  padding-right: 5px;
  font-size: 10px; 
  color: #888;
}

.week-timeline-entries {
  position: absolute;
  top: 10px;
  left: 45px; 
  right: 10px;
  height: 100%;
}

.week-timeline-entry {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(200, 200, 200, 0.3);
  border-left: 2px solid #c84747;
  padding: 5px;
  box-sizing: border-box;
  font-size: 10px; 
  overflow: hidden;
}

.week-entry-time {
  font-weight: bold;
  font-size: 9px;
}

.day-view {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-bottom: 20px;
}

.day-view-header {
  text-align: center;
  font-weight: bold;
  padding: 10px;
  background-color: #f0f0f0;
  position: sticky;
  top: 0;
  z-index: 5;
}

.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
}

.timeline-slot {
  position: relative;
  height: 60px;
  border-bottom: 1px solid #eee;
}

.timeline-time {
  position: absolute;
  top: 2px;
  left: 0;
  width: 60px;
  text-align: right;
  padding-right: 10px;
  font-size: 12px;
  color: #888;
}

.timeline-entries {
  position: absolute;
  top: 7px;
  left: 70px;
  right: 10px;
  height: 100%;
}

.timeline-entry {
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(200, 200, 200, 0.3);
  border-left: 3px solid #c84747;
  padding: 5px;
  box-sizing: border-box;
  font-size: 12px;
  overflow: hidden;
  z-index: 1;
}

.entry-time {
  font-weight: bold;
}

.entry-details {
  color: #555;
  margin-top: 5px;
}

.entry-description {
  margin-top: 5px;
  color: #777;
}

@media (max-width: 950px) {
  .week-timeline-time {
    margin-left: -22px;
    font-size: 9px; 
  }
  
  .week-timeline-entries {
    left: 5px; 
  }
  
  .week-entry-time {
    font-size: 9px;
  }

  .week-day-header {
    font-size: 12px;
  }
}

@media (max-width: 720px) {
  .profile-header img {
    width: 45px;
    height: 45px;
  }
  
  .profile-header .company-name {
    font-size: 18px;
  }

  .unavailable-label {
    font-size: 12px;
  }

  .availability-time {
    font-size: 8px;
  }

  .week-timeline-time {
    margin-left: -22px;
    font-size: 7px; 
  }
  
  .week-timeline-entries {
    left: 2px; 
  }
  
  .week-entry-time {
    font-size: 7px;
  }

  .week-day-header {
    font-size: 10px;
  }
}

@media (max-width: 650px) {
  .schedule {
    width: 100%;
  }

  .profile-header img {
    width: 40px;
    height: 40px;
  }
  
  .profile-header .company-name {
    font-size: 16px;
  }

  .profile-header img {
    width: 45px;
    height: 45px;
  }
  
  .profile-header .company-name {
    font-size: 18px;
  }

  .unavailable-label {
    font-size: 12px;
  }

  .availability-time {
    font-size: 8px;
  }

  .week-timeline-time {
    font-size: 7px; 
  }
  
  .week-entry-time {
    font-size: 6px;
  }

  .week-day-header {
    font-size: 8.5px;
  }
}

@media (max-width: 520px) {
  .unavailable-label {
    font-size: 0px;
  }

  .availability-time {
    font-size: 0px;
  }

  .week-day-header {
    font-size: 7px;
  }

  .profile-header img {
    width: 35px;
    height: 35px;
  }
  
  .profile-header .company-name {
    font-size: 14px;
  }
}
