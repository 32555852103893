.Header {
  background: white;
  width: 100%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #b0b0b0;
}

.form-header-width {
  display: flex;
  justify-content: space-between;
  height: 55px;
  max-width: 75%;
  margin-right: 15px;
  width: 100%;
  align-items: center;
}

.brand {
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  font-weight: 700;
  margin-left: 15px;
  color: #045096;
  cursor: pointer;
}

.header-logo {
  width: 45px;
  height: auto;
  margin-right: 10px;
}

.brand-name {
  display: inline;
  margin-bottom: 10px;
}

.brand-name:hover {
  cursor: pointer;
}

.user-nav {
  display: flex;
  justify-content: right;
}

.header-nav-button {
  background: #23313d;
  font-family: 'Raleway', sans-serif;
  font-size: 1.1rem;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: right;
}

.sign-in-button {
  border-radius: 12px;
  padding: 0.6rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;
}

.profile-button {
  position: relative;
}

.profile-button img.profile-picture {
  width: 40px;
  height: 40px;
  margin-top: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-button img.profile-picture:hover {
  border: 1px solid #23313d;
}

.sign-in-button:hover {
  background-color: #333a41;
  color: white;
}

.header-dropdown-menu {
  position: absolute;
  top: 50px;
  right: -155px;
  background: white;
  border: 1px solid #b0b0b0;
  border-radius: 5px;
  width: 200px;
  z-index: 999;
}

.dropdown-profile {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #b0b0b0;
  cursor: pointer;
}

.dropdown-profile img.dropdown-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.dropdown-profile-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
}

.dropdown-profile-text span {
  display: block;
}

.dropdown-profile-text .view-profile {
  font-weight: bold;
}

.dropdown-profile-text .dropdown-username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px; 
  position: relative;
  padding-right: 1em; 
}

.header-dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-dropdown-menu ul li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.header-dropdown-menu ul li:hover {
  background-color: #f1f1f1;
}

.header-dropdown-menu ul li img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
}

.header-unread-indicator {
  position: absolute;
  top: 0;
  right: -1;
  width: 12px;
  height: 12px;
  background-color: #045096;
  border: 0.8px solid white;
  border-radius: 50%;
}

.messages-unread-indicator {
  position: absolute;
  margin-top: -20px;
  right: -1;
  width: 10px;
  height: 10px;
  background-color: #045096;
  border: 0.8px solid white;
  border-radius: 50%;
}

.invoices-unread-indicator {
  position: absolute;
  margin-top: -20px;
  right: -1;
  width: 10px;
  height: 10px;
  background-color: #045096;
  border: 0.8px solid white;
  border-radius: 50%;
}

.schedule-unread-indicator {
  position: absolute;
  margin-top: -20px;
  right: -1;
  width: 10px;
  height: 10px;
  background-color: #045096;
  border: 0.8px solid white;
  border-radius: 50%;
}

@media (max-width: 1000px) {
  .form-header-width {
    width: 100%;
    max-width: 97%;
  }

  .header-dropdown-menu {
    position: absolute;
    top: 35px;
    right: 0px;
    background: white;
    border: 1px solid #b0b0b0;
    border-radius: 5px;
    width: 200px;
    z-index: 999;
  }
}

@media (max-width: 768px) {
  .form-header-width {
    margin-right: 0%;
  }

  .user-nav, 
  .brand {
    margin-top: 10px;
  }
}

@media (max-width: 630px) {
  .form-header-width {
    height: 45px;
    max-width: 97%;
    width: 100%;
  }

  .brand {
    font-size: 1.2rem;
  }

  .header-logo {
    width: 35px;
    height: auto;
  }

  .header-nav-button {
    font-size: 0.95rem;
  }

  .sign-in-button {
    padding: 0.5rem 0.8rem;
  }

  .profile-button img.profile-picture {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 400px) {
  .Header {
    height: 40px;
  }

  .form-header-width {
    height: 26px;
    max-width: 100%;
    margin-top: 9px;
  }

  .brand {
    font-size: 1.5rem;
    margin-left: -1em;
  }

  .brand-name { 
    margin-left: -5px;
  }

  .header-logo {
    width: 25px;
    height: auto;
  }

  .header-nav-button {
    font-size: 0.8rem;
  }

  .sign-in-button {
    padding: 0.4rem 0.7rem;
  }

  .profile-button img.profile-picture {
    width: 25px;
    height: 25px;
  }

  .header-dropdown-menu {
    right: -145px;
  }
}