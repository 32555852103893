.link-bank {
  background-color: white;
}

.link-bank-container {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  max-width: 70%;
  margin: 50px auto;
  margin-bottom: 150px;
  border: 1px solid #e3e3e3;
}

.link-bank-method {
  font-size: 1.8rem;
  font-weight: bold;
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 40px;
  display: block;
  text-align: center;
}

.link-bank-input-container {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.link-bank-input-container input {
  position: relative;
  width: 100%;
  margin-bottom: 15px;
}

.link-bank input[type="text"], 
.link-bank input[type="password"] {
  width: calc(50% - 10px); 
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.link-bank-eye-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  color: #045096;
}

button.link-bank-method-button {
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: calc(50% - 10px); 
  transition: all 0.3s ease;
  margin-top: 25px;
}

button.link-bank-method-button:hover {
  border-color: #23313d;
  color: #23313d;
}

.link-bank-error-message {
  color: red;
  margin-top: 20px;
  font-size: 1rem;
  margin-left: 25%;
}

@media (max-width: 1000px) {
  .link-bank input[type="text"], 
  .link-bank input[type="password"] {
    width: calc(95% - 10px); 
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
  }
}

@media (max-width: 680px) {
  .link-bank-container {
    max-width: 100%;
  }
}

