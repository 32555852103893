.company-highlights-container {
  max-width: 65%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #f1f0f0;
  margin-bottom: 300px;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
}

.highlights-container {
  margin-right: 7.7%;
}

.company-highlight {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.highlight-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.highlight-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 18px;
}

.highlight-date {
  font-size: 0.9rem;
  color: #888;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: -7px;
}

.highlight-menu {
  position: relative;
  margin-bottom: 5px;
}

.highlight-menu button {
  position: relative;
}

.highlight-menu-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  right: -20px;
  z-index: 300;
}

.highlight-dropdown-menu {
  position: absolute;
  right: 10px;
  top: 50px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 100;
}

.highlight-dropdown-menu button {
  background: none;
  border: none;
  padding: 10px;
  width: 120px;
  text-align: left;
  cursor: pointer;
}

.highlight-dropdown-menu button:hover {
  background: #f0f0f0;
}

.highlight-attachments {
  display: flex;
  align-items: center;
  margin-top: 2.5px;
  margin-left: 25px;
  margin-bottom: 10px;
  height: 100%;
  flex-grow: 1;
  gap: 5px;
}

.highlight-attachment-wrapper {
  position: relative;
  display: inline-block;
}

.highlight-attachments__icon {
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 50%;
}

.highlight-description {
  font-size: 16px;
  color: #333;
}

.no-highlights-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.no-highlights-icon {
  width: 100px;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 100px;
  color: #666;
}

.no-highlights-container p {
  font-size: 1.7rem;
  color: #666;
  font-family: 'Fredoka One', cursive;
}

.more-button {
  background: none;
  border: none;
  color: #045096;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
}

.more-button:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
 .highlight-attachments {
    margin-left: -5px;
 }

 .highlight-attachments__icon {
  width: 75px;
  height: 90px;
  object-fit: cover;
  border-radius: 10px;
  }
}

@media (max-width: 1000px) {
  .company-highlights-container {
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
  }

  .highlights-container {
    margin-right: 0%;
  }

  .highlight-attachments__icon {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .highlight-attachments {
    margin-left: 0px;
  }

  .company-highlight {
    margin-bottom: 0px;
  }
}

@media (max-width: 768px) {
  .highlight-title {
    font-size: 18px;
  }

  .highlight-description {
    font-size: 14px;
  }

  .highlight-attachments__icon {
    width: 70px;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
  }
}


@media (max-width: 600px) {
  .highlight-attachments {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .highlight-attachments__icon {
    width: 110px;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media (max-width: 550px) {
  .highlight-attachments {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .highlight-attachments__icon {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media (max-width: 350px) {
  .highlight-attachments__icon {
    width: 80px;
    height: 80px;
  }
}
