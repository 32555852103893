.uc-create-profile {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 15px;
  width: 70%;
  margin: 50px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}

.create-profile-container {
  width: 70%;
  margin: 0 auto;
}

.create-profile-header {
  font-weight: bold;
  color: #23313d;
  display: block; 
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.7rem;
  margin-bottom: 1.5rem;
}

.file-input-profile-picture {
  display: inline-block;
  background-color: #045096;
  color: white;
  padding: 10px 15px;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  text-align: left;
  width: 35%;
  transition: background-color 0.3s;
  margin-top: 0px;
}

.file-input-profile-picture:hover {
  background-color: #23313d;
}

.profile-picture-preview {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  align-items: left;
  align-content: left;
}

.file-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.file-name-display {
  font-size: 1rem;
  color: #666;
  display: block;
  text-align: left;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.background-picture-options {
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  margin-bottom: 15px;
}

.default-background-button {
  background-color: #045096;
  color: white;
  padding: 10px 15px;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  width: 40%;
  transition: background-color 0.3s;
  margin-right: 10px; 
  text-align: left;
  margin-bottom: 5px; 
}

.default-background-button:hover {
  background-color: #23313d;
}

.or-divider {
  font-size: 1rem;
  color: #666;
  margin: 30px 30px;
  margin-right: 40px;
  font-family: 'Poppins', sans-serif;
}

.file-input-background {
  background-color: #045096;
  color: white;
  padding: 10px 15px;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  width: 40%;
  transition: background-color 0.3s;
  margin-right: 10px; 
  text-align: left;
  margin-bottom: 5px; 
}

.file-input-background:hover {
  background-color: #23313d;
}

.background-picture-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}

.uc-create-profile input[type='text'],
.uc-create-profile input[type='email'],
.uc-create-profile input[type='password'],
.uc-create-profile select,
.uc-create-profile textarea {
  width: calc(100% - 20px); 
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.uc-create-profile textarea {
  height: 100px; 
}

.valid {
  background-image: url('/public/icons/check.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 25px 25px;
  background-color: #e8f5e9;
  padding-right: 40px; 
}

.invalid {
  background-color: #ffebee; 
}

.create-terms-container {
  color: #5f5f5f;
  margin-top: 15px;
  margin-bottom: 15px;
}

.terms-click {
  color: rgb(3, 3, 193);
  cursor: pointer;
  margin-left: 5px;
}

.terms-click:hover {
  text-decoration: underline;
}

.create-profile-button {
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem; 
  margin-top: 20px; 
  width: 80%;
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 10px 40px;
  transition: all 0.3s ease;
  align-self: center;  
  font-family: 'Raleway', sans-serif;
}

.create-profile-button:hover {
  border-color: #23313d;
  color: #23313d;
}

.cp-error-message {
  color: #d9534f;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 7px;
}

@media (max-width: 1230px) {
  .default-background-button,
  .file-input-background,
  .file-input-profile-picture {
    font-size: 0.8rem;
  }

  .create-profile-container {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .default-background-button,
  .file-input-background,
  .file-input-profile-picture {
    font-size: 0.5rem;
    width: 35%;
  }

  .create-profile-header {
    font-size: 1.5rem;
  }

  .or-divider {
    font-size: 0.7rem;
    color: #666;
    margin: 10px 10px;
    margin-right: 20px;
    font-family: 'Poppins', sans-serif;
  }

  .uc-create-profile {
    width: 110%;
    max-width: 115%;
    padding: 0px;
    margin: 0px; 
  }

  .uc-create-profile input[type='text'],
  .uc-create-profile input[type='email'],
  .uc-create-profile input[type='password'],
  .uc-create-profile textarea {
    width: calc(100% - 20px); 
    font-size: 0.8rem;
  }

  .create-profile-container {
    width: 100%;
  }

  .create-profile-button {
    font-size: 0.8rem; 
  }

  .terms-container {
    font-size: 12px;
  }
}
