.post-highlights {
  background-color: white;
}

.post-highlights-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 150px;
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #e3e3e3;
}

.form-header {
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  margin-top: -20px;
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.7rem;
}

.highlights-form {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  width: 100%;
}

.highlights-form input[type='text'],
.highlights-form textarea {
  width: calc(100% - 20px);
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.highlights-form textarea {
  height: 200px;
}

.file-input-label {
  display: block;
  background-color: #045096;
  font-family: 'Raleway', sans-serif;
  color: white;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  margin-bottom: 30px;
}

.file-input-label:hover {
  background-color: #23313d;
}

.file-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.file-name-display {
  font-size: 1rem;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.file-item {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 5px;
}

.file-item span {
  margin-right: 10px; 
}

.remove-button {
  background: none;
  border: none;
  color: #d9534f;
  font-size: 0.8rem;
  cursor: pointer;
  position: absolute;
  right: -5px;
  top: -3px;
}

.remove-button:hover {
  color: #c9302c;
}

.error-message {
  color: #d9534f;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 7px;
}

.post-button {
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem; 
  margin-top: 20px; 
  width: 80%;
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 10px 40px;
  transition: all 0.3s ease;
  align-self: center;
  font-family: 'Raleway', sans-serif;
}

.post-button:hover {
  border-color: #23313d;
  color: #23313d;
  background-color: transparent;
}

@media (max-width: 1000px) {
  .highlights-form {
    width: 80%;
  }

  .form-header {
    font-size: 2rem;
  }

  .post-highlights-container {
    margin-top: 10px;
    margin-bottom: 100px;
    max-width: 90%;
  }

  .file-input-label {
    width: 40%;
  }
}

@media (max-width: 600px) {
  .post-highlights-container {
    padding: 10px;
  }

  .form-header {
    font-size: 1.5rem;
  }

  .file-input-label {
    width: 50%;
  }

  .highlights-form textarea {
    height: 100px;
  }

  .file-item span {
    margin-right: 0px; 
  }

  .highlights-form input[type='text'],
  .highlights-form textarea {
    font-size: 0.8rem;
  }

  .file-input-label {
    font-size: 0.7rem;
  }

  .file-name-display {
    font-size: 0.7rem;
  }
  
  .post-button {
    font-size: 1.1rem; 
    width: 60%;
    padding: 6px 0px;

  }
}