.company-reviews-container {
  max-width: 65%;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #f1f0f0;
  margin-bottom: 100px;
  font-family: 'Poppins', sans-serif;
  margin-top: 20px;
}

.reviews-container {
  margin-right: 7.7%;
}

.company-review {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.company-review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-review-header img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.user-id-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.user-id {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.3rem;
  z-index: 300;
}

.user-id:hover {
  color: #484848;
  text-decoration: underline;
}

.company-review-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 15px;
  margin-bottom: 15px;
}

.company-review-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 100px;
}

.star-ratings-container {
  width: 150px; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.company-review-ratings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.star-ratings {
  display: flex;
  align-items: center;
}

.star-ratings span {
  min-width: 80px;
  margin-right: 35px;
  font-size: 1rem;
  margin-top: 5px;
}

.star-ratings .star {
  color: gray;
  margin: -30px;
  font-size: 1.7rem;
}

.star-ratings .star.filled {
  color: gold;
}

.company-review-attachments {
  display: flex;
  align-items: center; 
  margin-top: 2.5px;
  margin-left: 25px;
  height: 100%; 
  flex-grow: 1;
  gap: 5px; 
}

.attachment-wrapper {
  position: relative;
  display: inline-block; 
}

.company-review-attachments__icon {
  width: 90px; 
  height: 90px; 
  object-fit: cover; 
  margin-right: 10px;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 50%; 
}

.review-description {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.more-button {
  background: none;
  border: none;
  color: #045096;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
}

.more-button:hover {
  text-decoration: underline;
}

.no-cposts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 300px;
}

.no-cposts-container p {
  font-size: 1.7rem;
  color: #666;
  font-family: 'Fredoka One', cursive;
}

.save-button {
  background-color: transparent;
  color: #045096;
  padding: 5px 10px;
  border: 2px solid #045096;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 10px;
  transition: all 0.3s;
  font-family: 'Raleway', sans-serif;
  z-index: 700;
}

.save-button.saved {
  border-color: green;
  color: green;
}

.save-button:hover:not(.saved) {
  border-color: #23313d;
  color: #23313d;
  background: transparent;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-number {
  border: 1px solid #ddd;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  background-color: #fff;
}

.page-number.active {
  background-color: #045096;
  color: white;
}

.page-number:hover {
  background-color: #f0f0f0;
}

.page-number.active:hover {
  background-color: #045096;
}

@media (max-width: 1100px) {
  .company-review-attachments__icon {
    width: 80px; 
    height: 90px; 
    object-fit: cover; 
    border-radius: 10px;
  }
}

@media (max-width: 1000px) {  
  .reviews-container {
    margin-right: 0%;
  }

  .company-reviews-container {
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
  }

  .company-review-header img {
    width: 60px;
    height: 60px;
  }

  .company-review-attachments {
    margin-left: 30px;
  }

  .company-review-attachments__icon {
    width: 90px; 
    height: 90px; 
    object-fit: cover; 
    border-radius: 10px;
  }

  .company-review {
    margin-bottom: 0px;
  }
}

@media (max-width: 700px) {
  .company-review-attachments__icon {
    width: 80px; 
    height: 90px; 
    object-fit: cover; 
    border-radius: 10px;
  }
}

@media (max-width: 600px) { 
  .company-review-attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .company-review-attachments__icon {
    width: 40px; 
    height: 40px; 
    max-height: 50px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 550px) { 
  .company-review-attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-right: 0px;
  }

  .company-review-attachments__icon {
    width: 40px; 
    height: 40px; 
    max-height: 50px;
    object-fit: cover;
    border-radius: 10px;
  }

  .company-review-attachments {
    margin-left: 30px;
  }

  .user-id {
    font-size: 1.1rem;
  }

  .save-button {

    font-size: 0.6rem;
  }
}

@media (max-width: 350px) { 
  .company-review-attachments__icon {
    width: 0px; 
    height: 0px; 
  }
}
