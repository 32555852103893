.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px; 
  max-width: 90%; 
  box-sizing: border-box;
  position: relative;
}

.confirmation-modal-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px; 
}

.confirmation-modal-body h2 {
  font-size: 1.5rem; 
  color: #23313d; 
  margin-bottom: 20px; 
}

.code-inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.code-inputs input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: 'Poppins', sans-serif;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.confirmation-modal-text-message {
  display: inline;
  margin: 0;
  font-size: 14px;
  color: #666;
  font-family: 'Poppins', sans-serif;
}

.resend-link {
  margin-left: 5px;
  color: #23313d;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease; 
  font-family: 'Poppins', sans-serif;
}

.resend-link:hover {
  color: #5075a5; 
}

.confirmation-modal-button button {
  background-color: #23313d;
  color: white;
  border: none;
  padding: 12px 20px; 
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
  width: calc(100% - 40px); 
  box-sizing: border-box; 
  font-family: 'Raleway', sans-serif;
}

.confirmation-modal-button button:hover {
  background-color: #333a41;
  color: white;
}
