.notifications-container {
  padding: 20px;
  border-radius: 10px;
  width: 60%;
}

.notifications-option {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}

.notifications-container h3 {
  font-size: 21px;
  margin: 0 0 5px 0;
  text-align: left;
  margin-bottom: 15px;
  color: #222; 
}

.notifications-option h4 {
  font-size: 18px;
  font-weight: normal; 
  margin: 0 0 5px 0;
  text-align: left;
  margin-bottom: 15px;
  color: #000; 
}

.radio-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.radio-group label {
  font-size: 16px; 
  display: flex;
  margin-right: 10px; 
  color: #000;
}

.radio-group input[type="radio"] {
  margin-right: 5px;
  margin-bottom: 15px;
}

.notifications-option p {
  margin: 5px 0 0;
  font-size: 12px;
  color: #777; 
  text-align: left;
}

.notifications-option hr {
  border: none;
  margin: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

@media (max-width: 650px) {
  .notifications-container {
    width: 90%;
  }
}
