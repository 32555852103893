.create-profile {
  background-color: white; 
}

.create-profile-tabs {
  display: flex;
  justify-content: center;
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
}

.create-profile-tabs button {
  background-color: #045096;
  color: white;
  padding: 15px 60px;
  border: none;
  flex: 1 1 calc(30% - 20px); 
  font-size: 1.7rem;
  cursor: pointer;
  border-radius: 15px;
  transition: background-color 0.3s, transform 0.3s;
  margin: 0 10px;
  margin-top: 55px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-family: 'Raleway', sans-serif;
}

.create-profile-tabs button:hover,
.create-profile-tabs button.active {
  background-color: #23313d;
  transform: scale(1.05);
}

.create-profile-content {
  margin-top: 50px;
  min-width: 100%;
}

@media (max-width: 768px) {
  .create-profile-tabs {
    justify-content: center;
    margin: 0 10px;
    max-width: 80%;
    margin-left: 10%;
  }
  
  .create-profile-tabs button {
    max-width: 50%;
    margin: 5px 0;
    margin-top: 10px;
    padding: 15px 20px;
    margin-bottom: -20px;
  }
}

@media (max-width: 540px) {
  .create-profile-tabs {
    justify-content: center;
    margin: 0 10px;
    margin-left: 10%;
  }
  
  .create-profile-tabs button {
    flex: 1 1 100%; 
    margin: 5px 0;
    margin-top: 5px;
    font-size: 1.2rem; 
    padding: 10px 40px; 
    margin-bottom: -30px;

  }
}
