.delete-review-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9990;
}

.delete-review-modal-content {
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: rgb(211, 211, 211);
  padding: 20px;
  border-radius: 10px;
  outline: none;
  max-height: 90vh;
  overflow: auto;
}

.delete-review-modal-actions {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-left: 2.5%;
  height: 50px;
  width: 95%;
  background-color: white;
  border-radius: 15px 15px 15px 15px;
  z-index: 3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #b0b0b0;
  border-right: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
}

.delete-review-yes-button {
  color: #045096;
  padding: 10px 60px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.delete-review-yes-button:hover {
  background-color: #f1f0f0;
  color: #ff0000;
}

.delete-review-no-button {
  color: #045096;
  padding: 10px 60px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.delete-review-no-button:hover {
  background-color: #f1f0f0;
  color: #23313d;
}

@media (max-width: 1000px) {
  .delete-review-modal-content {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .delete-review-modal-content {
    width: 60%;
  }
}

@media (max-width: 550px) {
  .delete-review-modal-sign-in-head {
    font-size: 1.2rem;
  }

  .delete-review-modal-actions {
    height: 40px;
  }

  .delete-review-yes-button {
    padding: 6px 30px;
    font-size: 0.85rem;
  }

  .delete-review-no-button {
    padding: 6px 30px;
    font-size: 0.85rem;
  }
}