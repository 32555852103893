.user-reviews-container {
  justify-content: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: #f1f0f0;
  margin-top: 20px;
  margin-bottom: 200px;
  font-family: 'Poppins', sans-serif;
  margin-right: auto;
  margin-left: auto;
  max-width: 65%;
}

.user-review {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-right: 7.7%;
}

.user-review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-review-header img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.company-id-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.company-id {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0px;
  z-index: 300;
}

.company-id:hover {
  color: #484848;
  text-decoration: underline;
}

.review-menu {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;
}

.review-menu-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2;
}

.edit-review-dropdown-menu {
  position: absolute;
  right: 0px;
  top: 30px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 3;
}

.edit-review-dropdown-menu button {
  background: none;
  border: none;
  padding: 10px;
  width: 100px;
  text-align: left;
  cursor: pointer;
}

.edit-review-dropdown-menu button:hover {
  background: #f0f0f0;
}

.review-date {
  font-size: 0.9rem;
  color: #888;
  margin-top: 12px;
  margin-right: 7px;
  z-index: 1;
}

.user-review-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 15px;
  margin-bottom: 15px;
}

.user-review-body {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 100px;
}

.star-ratings-container {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-review-ratings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}

.user-star-ratings {
  display: flex;
  align-items: center;
}

.user-star-ratings span {
  min-width: 80px;
  margin-right: 35px;
  font-size: 1rem;
  margin-top: 5px;
}

.user-star-ratings .star {
  color: gray;
  margin: -30px;
  font-size: 1.7rem;
}

.user-star-ratings .star.filled {
  color: gold;
}

.user-review-attachments {
  display: flex;
  align-items: center;
  margin-top: 2.5px;
  margin-left: 25px;
  height: 100%;
  flex-grow: 1;
  gap: 5px;
}

.attachment-wrapper {
  position: relative;
  display: inline-block;
}

.user-review-attachments__icon {
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 50%;
}

.user-review-description {
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  margin-bottom: 10px;
}

.more-button {
  background: none;
  border: none;
  color: #045096;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
}

.more-button:hover {
  text-decoration: underline;
}

.no-posts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-right: 8%;
}

.no-posts-icon {
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 100px;
  color: #666;
}

.no-posts-container p {
  font-size: 1.7rem;
  color: #666;
  font-family: 'Fredoka One', cursive;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.page-number {
  border: 1px solid #ddd;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  background-color: #fff;
}

.page-number.active {
  background-color: #045096;
  color: white;
}

.page-number:hover {
  background-color: #f0f0f0;
}

.page-number.active:hover {
  background-color: #045096;
}

@media (max-width: 1100px) {
  .user-review-attachments__icon {
    width: 80px;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media (max-width: 1000px) {
  .user-reviews-container {
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
    z-index: 1;
  }

  .user-review {
    border-bottom: 1px solid #b0b0b0;
    margin-bottom: 0px;
    margin-right: 0%;
  }

  .user-review-header img {
    width: 60px;
    height: 60px;
  }

  .user-review-attachments {
    margin-left: 30px;
  }

  .user-review-attachments__icon {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media (max-width: 700px) {
  .user-review-attachments__icon {
    width: 80px;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media (max-width: 630px) {
  .user-review-attachments {
    margin-left: 12px;
  }

  .user-review-attachments__icon {
    width: 60px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
  }

  .user-review-header img {
    width: 60px;
    height: 60px;
  }
  
  .company-id {
    font-size: 1.15rem;
  }

  .star-ratings-container {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .user-review-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .review-menu-button {
    font-size: 1.2rem;
  }

  .review-date {
    font-size: 0.7rem;
  }

  .user-star-ratings span {
    min-width: 80px;
    margin-right: 35px;
    font-size: 0.85rem;
    margin-top: 5px;
  }
  
  .user-star-ratings .star {
    color: gray;
    margin: -32px;
    font-size: 1.3rem;
  }

  .user-review-description {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: -10px;
  }

  .no-posts-icon {
    width: 50px;
    height: 50px;
  }
  
  .no-posts-container p {
    font-size: 1.2rem;
  }
}

@media (max-width: 500px) {
  .user-review-attachments__icon {
    width: 40px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
  }

  .company-id {
    font-size: 1.1rem;
  }
}

@media (max-width: 430px) {
  .user-review-attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 15px;
  }

  .user-review-attachments__icon {
    width: 50px;
    height: 40px;
    max-height: 50px;
    object-fit: cover;
    border-radius: 10px;
    margin-top: -5px;
  }
}

@media (max-width: 335px) {
  .user-review-attachments__icon {
    width: 0px;
    height: 0px;
  }
}
