.image-crop-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-crop-modal {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 400px;
  background: #333;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.controls button {
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 9px 20px;
  transition: all 0.3s ease;
}

.controls button:hover {
  border-color: #23313d;
  color: #23313d;
}

.controls .cancel-button {
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.controls .cancel-button:hover {
  background: #c82333;
  color: white;
}

@media (max-width: 768px) {
  .image-crop-modal {
    width: 95%;
  }

  .crop-container {
    height: 300px;
  }
}
