.edit-profile {
  background-color: white;
}

.edit-profile-layout {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.edit-profile-form {
  padding: 30px;
  margin-top: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  width: 100%;
  max-width: 50%;
  border: 1px solid #e3e3e3;
}

.edit-profile-container {
  width: 90%;
}

.edit-profile-header {
  font-weight: bold;
  display: block; 
  text-align: center;
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.7rem;
  margin-bottom: 1.5rem;
}

.edit-profile-form input[type='text'],
.edit-profile-form input[type='email'],
.edit-profile-form input[type='password'],
.edit-profile-form select,
.edit-profile-form textarea {
  width: calc(100% - 20px); 
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.edit-bio {
  width: calc(100% - 3px); 
}

.bio-textarea {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Poppins', sans-serif;
  resize: none;
}

.bio-word-count {
  text-align: right;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  color: #666;
}

.react-select {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.price-range-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.price-range-label {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
  text-align: left;
  color: #3f3f3f;
}

.price-range-inputs {
  display: flex;
  align-items: center;
  margin-bottom: -10px;
}

.price-input {
  width: calc(50% - 10px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-right: 10px;
}

.price-range-separator {
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  margin-right: 10px;
  margin-top: -15px;
}

.valid {
  background-image: url('/public/icons/check.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 25px 25px;
  background-color: #e8f5e9;
  padding-right: 40px; 
}

.invalid {
  background-color: #ffebee; 
}

textarea {
  height: 100px; 
}

.file-input-label {
  display: inline-block;
  background-color: #045096;
  color: white;
  padding: 10px 15px;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 5px;
  display: block;
  text-align: left;
  width: 35%;
  transition: background-color 0.3s;
  margin-top: 0px;
}

.file-input-label:hover {
  background-color: #23313d;
}

.file-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.background-picture-options {
  display: flex;
  justify-content: flex-start; 
  align-items: center;
  margin-bottom: 15px;
}

.default-background-button {
  background-color: #045096;
  color: white;
  padding: 10px 15px;
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  width: 40%;
  transition: background-color 0.3s;
  margin-right: 10px; 
}

.default-background-button:hover {
  background-color: #23313d;
}

.or-divider {
  font-size: 1rem;
  color: #666;
  margin: 30px 30px;
  margin-right: 40px;
  font-family: 'Poppins', sans-serif;
}

.default-background-button,
.file-input-background,
.file-input-profile-picture {
  text-align: left;
  margin-bottom: 5px; 
  display: block; 
  font-family: 'Poppins', sans-serif;
}

.file-name-display {
  font-size: 1rem;
  color: #666;
  display: block;
  text-align: left;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.profile-picture-preview {
  width: 20%;
  height: 20%;
  object-fit: cover;
  border-radius: 50%;
  align-items: left;
  align-content: left;
}

.background-picture-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-family: 'Raleway', sans-serif;
}

.update-profile-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: 48%; 
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  transition: all 0.3s ease;
  align-self: center;
}

.update-profile-button:hover {
  background-color: transparent;
  border-color: #23313d;
  color: #23313d;
}

.edit-profile-cancel-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: 48%; 
  border: 2px solid #ccc;
  background-color: transparent;
  color: black;
  transition: all 0.3s ease;
  align-self: center;
}

.edit-profile-cancel-button:hover {
  background-color: #f0f0f0;
}

.edit-profile-form .error-message {
  color: #d9534f;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 7px;
}

.delete-profile-button {
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  margin-top: 60px;
  width: 28%;
  background-color: transparent;
  color: #d9534f;
  border: 2px solid #d9534f;
  transition: all 0.3s ease;
  align-self: center;
}

.delete-profile-button:hover {
  background-color: #c9302c;
  color: white;
}

.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9990;
}

.delete-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 60%;
}

.delete-modal-content h2 {
  margin-bottom: 20px;
  max-width: 80%;
  margin-left: 10%;
}

.delete-modal-content label {
  display: block;
  margin-bottom: 10px;
  text-align: left; 
  margin-left: 20%;
}

.delete-modal-content input[type="password"] {
  width: calc(60% - 20px);
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.delete-modal-buttons {
  display: flex;
  justify-content: center; 
  gap: 20px; 
  margin-top: 20px;
}

.delete-modal-buttons button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.delete-modal-buttons button:nth-child(1) {
  border: 2px solid #ccc;
  background-color: transparent;
  color: #333;
}

.delete-modal-buttons button:nth-child(1):hover {
  background-color: #f0f0f0;
}

.delete-modal-buttons button:nth-child(2) {
  border: 2px solid #d9534f;
  background-color: transparent;
  color: #d9534f;
}

.delete-modal-buttons button:nth-child(2):hover {
  background-color: #c9302c;
  color: white;
}

.error-message {
  color: #d9534f;
  margin-top: 10px;
  text-align: center;
}

.floating-menu-button {
  display: none;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  margin-top: 50px;
}

.sidebar-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 1rem;
  border-radius: 10px;
  color: #333;
  font-family: 'Poppins', sans-serif;
  width: 175px;
  text-align: left;
  margin-right: 30px;
  margin-bottom: 3px;
  transition: background-color 0.3s;
}

.sidebar-button img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.sidebar-button:hover {
  background-color: #f0f0f0;
}

.sidebar-button.active {
  background-color: #e3e3e3;
}

.delete-sidebar-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 1rem;
  border-radius: 10px;
  color: #d9534f;
  font-family: 'Poppins', sans-serif;
  width: 175px;
  text-align: left;
  margin-right: 30px;
  transition: background-color 0.3s;
}

.delete-sidebar-button img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.delete-sidebar-button:hover {
  background-color: #f0f0f0;
}

@media (max-width: 1230px) {
  .default-background-button,
  .file-input-background,
  .file-input-profile-picture {
    font-size: 0.8rem;
  }

  .edit-profile-container {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .default-background-button,
  .file-input-background,
  .file-input-profile-picture {
    font-size: 0.7rem;
    width: 35%;
  }

  .edit-profile-layout {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .edit-profile-header {
    font-size: 1.8rem;
  }

  .edit-profile-form {
    margin-top: 10px;
    width: 100%; 
    max-width: 100%;
  }

  .or-divider {
    font-size: 0.7rem;
    color: #666;
    margin: 10px 10px;
    margin-right: 20px;
    font-family: 'Poppins', sans-serif;
  }

  .edit-profile-form input[type='text'],
  .edit-profile-form input[type='email'],
  .edit-profile-form input[type='password'],
  .edit-profile-form select,
  .edit-profile-form textarea {
    width: 100%; 
    font-size: 0.8rem;
  }

  .bio-textarea {
    width: 100%;

  }

  .price-range-label {
    font-size: 0.85rem;
  }

  .edit-profile-container {
    width: 100%;
  }

  .sidebar {
    display: none;
  }

  .floating-menu-button {
    display: block;
    position: fixed;
    top: 55px;
    left: 10px;
    background-color: #045096;
    color: white;
    padding: 10px 15px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
    font-size: 1.2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .floating-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    position: fixed;
    top: 115px;
    left: 10px;
    z-index: 1000;
    width: 150px;
  }

  .floating-menu .sidebar-button,
  .floating-menu .delete-sidebar-button {
    width: 100%;
    margin: 5px 0;
  }

  .edit-profile-form {
    width: 90%;
    max-width: 90%;
  }

  .update-profile-button {
    padding: 7px 20px;
    font-size: 0.87rem;

  }
  
  .edit-profile-cancel-button {
    padding: 7px 20px;
    font-size: 0.87rem;
  }
}

@media (max-width: 600px) {
  .default-background-button,
  .file-input-background,
  .file-input-profile-picture {
    font-size: 0.5rem;
    width: 35%;
  }

  .delete-modal-content h2 {
    max-width: 80%;
    font-size: 1.2rem;
  }
  
  .delete-modal-content label {
    margin-left: 10%;
  }
  
  .delete-modal-content input[type="password"] {
    width: calc(80% - 20px);
  }

  .edit-profile-layout {
    flex-direction: column;
    align-items: center;
  }

  .edit-profile-header {
    font-size: 1.5rem;
  }
}
