.payment-loader-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .payment-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .loader-svg {
    width: 550px;
    height: 550px;
  }
  
  .loader-path {
    stroke-dasharray: 600;
    stroke-dashoffset: 600;
    animation: drawAndContract 3s infinite ease-in-out;
  }
  
  @keyframes drawAndContract {
    0% {
      stroke-dashoffset: 600;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: -600;
    }
  }
  