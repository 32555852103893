.company-profile {
  background-color: #f1f0f0;
  justify-content: center;
}

.company-not-found {
  font-size: 1.5rem;
  margin-top: 50px;
  margin-bottom: 300px;
}

.external-company-buttons {
  top: 1px;
  right: 0px; 
  margin-right: 30px;
  position: absolute; 
  display: flex;
  align-items: center;
  width: 110px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 3;
  gap: 12px;
}

.company-message-button {
  position: absolute;
  top: 5px;
  right: -25px;
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 10px 17px;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  transition: all 0.3s ease;
}

.company-message-button:hover {
  border-color: #23313d;
  color: #23313d;
}

.external-calendar img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  left: -20px;
  display: flex;
  cursor: pointer;
}

.external-calendar img:hover {
  height: 32px;
  width: auto;
}

@media (max-width: 768px) {
  .company-message-button {
    padding: 7px 16px;
  }

  .external-calendar img {
    width: 28px;
    height: 28px;
    margin-top: 8px;
  }

  .external-calendar img:hover {
    width: 29px;
    height: 29px;
  }
}

@media (max-width: 650px) {
  .company-message-button {
    padding: 7px 14px;
  }

  .external-calendar img {
    width: 26px;
    height: 26px;
    margin-top: 8px;
  }

  .external-calendar img:hover {
    width: 27px;
    height: 27px;
  }

  .external-company-buttons {
    width: 100px;
  }
}

@media (max-width: 500px) {
  .company-message-button {
    padding: 7px 11px;
    font-size: 9px;
  }

  .external-calendar img {
    width: 22px;
    height: 22px;
    margin-top: 10px;
    margin-right: -40px;
  }

  .external-calendar img:hover {
    width: 23px;
    height: 23px;
  }

  .external-company-buttons {
    width: 90px;
  }
}