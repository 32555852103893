.profile-invoice {
  background-color: #f9f9f9;
}

.send-invoice-button {
  --primary-color: #045096;
  --hovered-color: #23313d;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 1.25rem;
  gap: 0.2rem;
  align-items: center;
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--primary-color);
  transition: all 0.3s ease;
  width: 32%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.send-invoice-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.send-invoice-button::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.send-invoice-button:hover::after,
.send-invoice-button.active::after {
  width: 100%;
}

.send-invoice-button:hover::before,
.send-invoice-button.active::before {
  width: 100%;
}

.send-invoice-button:hover,
.send-invoice-button.active {
  color: var(--hovered-color);
  border-color: var(--hovered-color);
}

.bank-info {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.link-bank-button {
  --primary-color: #045096;
  --hovered-color: #23313d;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 1rem;
  gap: 0.5rem;
  align-items: center;
  padding: 7px 10px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--primary-color);
  transition: all 0.3s ease;
  width: 25%;
  margin-top: 10px;
  border-radius: 5px;
}

.change-bank-button {
  --primary-color: #045096;
  --hovered-color: #23313d;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 1rem;
  gap: 0.5rem;
  align-items: center;
  padding: 7px 10px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--primary-color);
  transition: all 0.3s ease;
  width: 32%;
  margin-top: 10px;
  border-radius: 5px;
}

.link-bank-button::after, .change-bank-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.link-bank-button::before, .change-bank-button::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.link-bank-button:hover::after, .link-bank-button.active::after,
.change-bank-button:hover::after, .change-bank-button.active::after {
  width: 100%;
}

.link-bank-button:hover::before, .link-bank-button.active::before,
.change-bank-button:hover::before, .change-bank-button.active::before {
  width: 100%;
}

.link-bank-button:hover, .link-bank-button.active,
.change-bank-button:hover, .change-bank-button.active {
  color: var(--hovered-color);
  border-color: var(--hovered-color);
}

.bank-method {
  font-size: 22px;
  color: #555;
  margin-top: 10px; 
  margin-bottom: 10px;
  text-align: left;
}

.link-bank-statement {
  font-size: 22px;
  color: #555;
  margin-top: 10px; 
  margin-bottom: 10px;
  text-align: left;
}