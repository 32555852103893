.payment-history {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
}

.invoice-history-item {
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
}

.invoice-history-label {
  font-size: 1.2rem;
  color: #555;
  font-weight: bold;
}

.invoice-history-value {
  font-size: 1.2rem;
  color: #333;
}

.invoice-history-no-history {
  font-size: 22px;
  color: #6f6d6d;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 1000px) and (min-width: 651px) {
  .invoice-history-label {
    font-size: 1rem;
  }
  
  .invoice-history-value {
    font-size: 1rem;
  }
  
  .invoice-history-no-history {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .invoice-history-label {
    font-size: 1rem;
  }
  
  .invoice-history-value {
    font-size: 1rem;
  }
  
  .invoice-history-no-history {
    font-size: 18px;
  }
}

@media (max-width: 350px) {
  .invoice-history-label {
    font-size: 0.9rem;
  }
  
  .invoice-history-value {
    font-size: 0.9rem;
  }
  
  .invoice-history-no-history {
    font-size: 15px;
  }
}