.current-invoice {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
}

.current-invoice-details {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
}

.current-invoice-header {
  display: flex;
  justify-content: space-between;
}

.current-invoice-bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.current-invoice-company-name a {
  text-decoration: none;
  color: #555; 
  font-size: 1.2rem;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.current-invoice-company-name a:hover {
  color: #111;
}

.current-invoice-amount {
  font-size: 1.15rem;
  font-weight: bold;
  color: #111;
  font-family: 'Poppins', sans-serif;
}

.current-invoice-due-date {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.due-text-red {
  color: red;
  font-size: 1.1rem;
}

.due-text-yellow {
  color: #045096;
  font-size: 1.1rem;
}

.due-text-default {
  color: black;
}

.current-invoice-no-invoice {
  font-size: 22px;
  color: #6f6d6d;
  margin-bottom: 10px;
  margin-top: 10px;
}

.current-invoice-pay-button {
  --primary-color: #045096;
  --hovered-color: #23313d;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  gap: 0.5rem;
  align-items: center;
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  background: none;
  cursor: pointer;
  margin-top: 5px;
  color: var(--primary-color);
  transition: all 0.3s ease;
  align-self: flex-end;
  width: 10%;
}

.current-invoice-pay-button p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--primary-color);
}

.current-invoice-pay-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.current-invoice-pay-button p::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.current-invoice-pay-button:hover::after,
.current-invoice-pay-button.active::after {
  width: 100%;
}

.current-invoice-pay-button:hover p::before,
.current-invoice-pay-button.active p::before {
  width: 100%;
}

.current-invoice-pay-button:hover svg,
.current-invoice-pay-button.active svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.current-invoice-pay-button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.current-invoice-pay-button.active {
  --primary-color: var(--hovered-color);
}


@media (max-width: 1000px) and (min-width: 651px) {
  .current-invoice-company-name {
    font-size: 1rem;
  }
  
  .current-invoice-amount {
    font-size: 0.9rem;
  }
  
  .current-invoice-due-date {
    font-size: 0.8rem;
  }
  
  .current-invoice-no-invoice {
    font-size: 17px;
  }
  
  .current-invoice-pay-button {
    padding: 3px 7px;
  }
}

@media (max-width: 450px) {
  .current-invoice-company-name {
    font-size: 1rem;
  }
  
  .current-invoice-amount {
    font-size: 0.9rem;
  }
  
  .current-invoice-due-date {
    font-size: 0.8rem;
  }
  
  .current-invoice-no-invoice {
    font-size: 17px;
  }
  
  .current-invoice-pay-button {
    padding: 3px 7px;
  }
}