.change-password-container {
  background-color: #f9f9f9;
  width: 90%; 
}

.change-password-header {
  font-weight: bold;
  display: block; 
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.7rem;
  margin-bottom: 1.5rem;
}

.change-password-container form {
  margin-top: 30px;
}

.change-password-container input[type='password'] {
  width: calc(100% - 20px); 
  padding: 15px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.valid {
  background-image: url('/public/icons/check.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 25px 25px;
  background-color: #e8f5e9;
  padding-right: 40px; 
}

.invalid {
  background-color: #ffebee; 
}

.change-password-buttons-container {
  display: flex;
  margin-top: 10px;
  font-family: 'Raleway', sans-serif;
}

.change-password-update-profile-button {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: 48%; 
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  transition: all 0.3s ease;
  position: relative;
}

.change-password-update-profile-button:hover {
  background-color: transparent;
  border-color: #23313d;
  color: #23313d;
}

.password-change-error-message {
  color: #d9534f;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 7px;
}

.password-change-success-message {
  color: #5cb85c;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 7px;
}

@media (max-width: 768px) {
  .change-password-header {
    font-size: 1.8rem;
  }

  .edit-profile-form {
    margin-top: 10px;
    width: 100%; 
    max-width: 100%;
  }

  .change-password-container input[type='password'] {
    width: 100%; 
    font-size: 0.8rem;
  }

  .change-password-update-profile-button {
    padding: 7px 20px;
    font-size: 0.87rem;
  }
}

@media (max-width: 600px) {
  .change-password-header {
    font-size: 1.5rem;
  }
}
