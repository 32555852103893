.payment {
  background-color: white;
}

.create-payment-method {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  max-width: 70%;
  margin: 50px auto;
  border: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-payment-method h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #23313d;
  margin-bottom: 40px;
  text-align: center;
}

.create-payment-method input[type='text'] {
  width: calc(55% - 10px); 
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.card-element {
  padding: 15px;
  width: calc(55% - 10px);
  align-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

.create-payment-method-btn {
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: calc(50% - 10px); 
  transition: all 0.3s ease;
  margin-top: 25px;
}

.create-payment-method-btn:hover {
  border-color: #23313d;
  color: #23313d;
}

.error {
  color: red;
  margin-top: 10px;
  font-size: 1rem;
}

@media (max-width: 1000px) {
  .create-payment-method input[type='text'] {
    width: calc(95% - 10px); 
  }
  
  .card-element {
    width: calc(95% - 10px);
  }
}

@media (max-width: 680px) {
  .create-payment-method {
    max-width: 95%;
  }

  .create-payment-method input[type='text'] {
    width: calc(97% - 10px); 
  }
  
  .card-element {
    width: calc(97% - 10px);
  }

  .create-payment-method-btn {
    width: calc(80% - 10px); 
  }
}

@media (max-width: 680px) {
  .create-payment-method input[type='text'] {
    width: calc(100% - 1px); 
  }
  
  .card-element {
    width: calc(100% - 1px); 
  }

  .create-payment-method-btn {
    width: calc(85% - 10px); 
    font-size: 0.85rem;
  }
}