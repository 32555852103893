.terms-container {
  font-family: 'Poppins', sans-serif;
  color: #333;
  max-width: 70%;
  margin: 0 auto;
  padding: 50px 0;
  text-align: left;
}

.terms-title-banner {
  width: 100%;
  background-color: none;
  border-top: 1.5px solid #333; 
  position: relative;
  text-align: center;
  margin-bottom: 50px;
  top: 78px;
}

.terms-title {
  font-size: 2.3rem;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  position: absolute;
  left: 5%;
  top: -60px;
  bottom: 10px;
  z-index: 3;
  text-align: left;
}

.terms-banner {
  width: 100%;
  height: auto;
  filter: brightness(40%);
  z-index: 1;
}

.terms-header {
  font-size: 1.8rem;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  margin-top: 30px;
  margin-bottom: 10px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

.terms-paragraph {
  font-size: 1.2em;
  line-height: 1.5;
  margin-bottom: 20px;
}

.terms-list {
  font-size: 1.2em;
  line-height: 1.5;
  list-style: lower-alpha;
  margin-left: 20px;
  margin-bottom: 20px;
}

.terms-list li {
  margin-bottom: 10px;
}

@media (max-width: 750px) {
  .terms-container {
    padding: 20px;
    max-width: 100%;
  }

  .terms-header {
    font-size: 1.5rem;
  }

  .terms-paragraph {
    font-size: 1em;
  }

  .terms-list {
    font-size: 1em;
  }
}
