.uc-create-profile {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 15px;
  width: 70%; 
  margin: 50px auto; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  border: 1px solid #e3e3e3; 
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
}

.create-bio {
  width: calc(100% - 15px); 
}

.create-bio div {
  margin-top: -15px;
  margin-bottom: 10px;
}

.create-price-range-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.create-price-range-label {
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
  text-align: left;
  color: #3f3f3f;
}

.create-price-range-inputs {
  display: flex;
  align-items: center;
  margin-bottom: -10px;
}

.create-price-input {
  width: calc(50% - 10px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-right: 10px;
}

.create-price-range-separator {
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
  margin-right: 10px;
  margin-top: -15px;
}

@media (max-width: 768px) {
  .react-select__control,
  .react-select__input input,
  .react-select__control {
    font-size: 0.8rem;
  }

  .create-price-range-label {
    font-size: 0.8rem;
  }
}