.privacy-container {
  padding: 20px;
}

.privacy-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.privacy-option label {
  text-align: left;
  margin-top: 20px;
  margin-right: 40px;
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  margin-top: 15px;
  width: 3.5em;
  height: 4em;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(182, 182, 182);
  transition: .4s;
  border-radius: 10px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 8px;
  left: 0.05em;
  bottom: 0.005em;
  transform: rotate(270deg);
  background-color: rgb(255, 255, 255);
  transition: .4s;
}

.switch input:checked + .slider {
  background-color: #21cc4c;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  transform: translateX(1.5em);
}
