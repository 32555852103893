.user-reviews-container {
  background-color: #f1f0f0;
}

.no-saved-posts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-right: 8%;
}

.no-saved-posts-container p {
  font-size: 1.7rem;
  color: #666;
  font-family: 'Fredoka One', cursive;
}