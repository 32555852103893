.edit-review-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-review-modal-content {
  background: #f1f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50%;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.edit-review-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  background: transparent;
  color: #333;
}

.edit-review-form-header {
  font-size: 1.7rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #23313d;
  font-family: 'Montserrat', sans-serif;
}

.edit-review-rating-container {
  display: grid;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 0.5rem;
  grid-template-columns: max-content auto;
  font-family: 'Poppins', sans-serif;
}

.edit-review-form-field {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'Poppins', sans-serif;
}

.edit-review-rating-label {
  font-size: 1.2rem;
  margin-top: 5px;
  font-family: 'Poppins', sans-serif;
}

.edit-review-ratings {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-family: 'Poppins', sans-serif;
}

.edit-review-star {
  font-size: 1.8rem;
  margin-right: 5px;
  color: #ccc;
  cursor: pointer;
  transition: color 0.3s;
}

.edit-review-star.active,
.edit-review-star:hover {
  color: #ffcc00;
}

.edit-review-review-form input[type='text'],
.edit-review-review-form textarea {
  width: 95%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 0.9rem;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.edit-review-review-form textarea {
  height: 100px;
}

.edit-review-suggestions-list {
  position: absolute;
  z-index: 1000;
  width: 50%;
  margin-top: 70px;
  max-height: 100px;
  overflow-y: auto;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-left: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.edit-review-suggestions-list li {
  padding: 8px 10px;
  cursor: pointer;
  text-align: left;
  list-style-type: none;
  font-family: 'Poppins', sans-serif;
}

.edit-review-suggestions-list li:hover {
  background-color: #f2f2f2;
}

.edit-review-suggestions-list li:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.edit-review-company-user-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.edit-review-file-input-label {
  display: inline-block;
  background-color: #045096;
  color: white;
  padding: 8px 10px;
  font-family: 'Inter-SemiBoldItalic', sans-serif;
  font-size: 0.7rem;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  text-align: left;
  width: 20%;
  transition: background-color 0.3s;
}

.edit-review-file-input-label:hover {
  background-color: #23313d;
}

.file-input-hidden {
  display: none;
}

.edit-review-file-name-display {
  font-size: 0.9rem;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.edit-review-file-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.edit-review-file-item span {
  margin-right: 10px;
}

.edit-review-remove-button {
  background: none;
  border: none;
  color: #d9534f;
  font-size: 0.8rem;
  cursor: pointer;
}

.edit-review-remove-button:hover {
  color: #c9302c;
}

.error-message {
  color: #d9534f;
  text-align: left;
  margin-top: -5px;
  margin-bottom: 5px;
}

.post-button {
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  width: 100%;
  border: 2px solid #045096;
  background-color: transparent;
  color: #045096;
  padding: 8px;
  transition: all 0.3s ease;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

.post-button:hover {
  border-color: #23313d;
  color: #23313d;
  background-color: transparent;
}

.edit-review-review-form textarea::-webkit-scrollbar,
.edit-review-modal-content::-webkit-scrollbar {
  width: 8px;
}

.edit-review-review-form textarea::-webkit-scrollbar-thumb,
.edit-review-modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.edit-review-review-form textarea::-webkit-scrollbar-thumb:hover,
.edit-review-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.edit-review-review-form textarea::-webkit-scrollbar-track,
.edit-review-modal-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

@media (max-width: 1050px) {
  .edit-review-modal-content {
    width: 90%;
    margin-right: 9%;
  }

  .file-input-label,
  .post-button {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .edit-review-rating-container {
    grid-template-columns: 1fr;
  }

  .edit-review-rating-label,
  .edit-review-ratings {
    justify-content: center;
    font-size: 1rem;
  }

  .edit-review-star {
    font-size: 1.5rem;
  }

  .edit-review-file-input-label {
    max-width: 40%;
    width: 40%;
  }
}
