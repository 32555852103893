.search-results {
  background: #f1f0f0;
}

.search-results-header {
  display: flex;
  flex-direction: column;
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
  background: #f1f0f0;
  margin-bottom: 70px;
  padding: 10px;
}

.search-results-title {
  color: #000000;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin: 10px 0;
}

.results-amount {
  color: #000000;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  font-weight: 500;
  align-self: flex-start;
}

.pagination-info {
  align-self: flex-end;
  text-align: right;
  margin-right: 10%;
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
}

.searched-results-box {
  display: flex;
  flex-direction: column;
  max-width: 92%;
}

.search-results-company-profile {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  flex-direction: column;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.past-review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-searched-picture {
  border-radius: 50%;
  width: 12%;
  height: 12%;
  object-fit: cover;
  margin-bottom: 30px;
}

.searched-company-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
  margin-bottom: 20px;
}

.searched-company-id {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.3rem;
}

.searched-company-id:hover {
  color: #484848;
  text-decoration: underline;
}

.searched-company-location {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 9px;  
  margin-bottom: 3px;  
  margin-left: -5px;
  color: #333;
}

.searched-location-icon {
  width: 15px;
  height: 15px;
  background-size: cover;
  position: relative;
  margin-right: 3px;
}

.past-review-title {
  flex-direction: row;
}

.company-starting-price {
  font-weight: 300;
  color: #333;
}

.searched-company-town {
  font-size: 15px;
  color: #333;
  text-align: right;
}

.past-review-company-type {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-top: 5px;
  margin-bottom: 15px;
}

.past-review-company-type > div {
  margin-bottom: 10px;
}

.past-review-company-type strong {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.past-review-company-type .company-type {
  font-weight: bold;
}

.past-review-company-type .specialties {
  font-weight: normal;
  margin-left: 10px;
}

.past-review-company-type img {
  margin-right: 10px;
  height: 17px;
  width: auto;
}

.searched-attachments {
  display: flex;
  align-items: center; 
  margin-top: 5px;
  margin-left: 90px;
  height: 100%; 
  flex-grow: 1;
}

.attachment-wrapper {
  position: relative;
  display: inline-block;
}

.searched-attachments__icon {
  width: 90px; 
  height: 90px; 
  object-fit: cover; 
  margin-right: 10px;
  border-radius: 10px;
}

.no-searched-results {
  margin-bottom: 35%;
}

.no-searched-results h3 {
  font-size: 2rem;
}

.no-searched-results p {
  font-size: 1.2rem;
  margin-bottom: -20px;
}

.no-searched-results-text {
  text-align: left;
  margin-top: 50px;
  font-family: 'Poppins', sans-serif;
  gap: -40px;
}

.searched-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-right: 17%;
}

.searched-page-number {
  border: 1px solid #ddd;
  padding: 8px 12px;
  margin: 0 4px;
  cursor: pointer;
  background-color: #fff;
  font-family: 'Raleway', sans-serif;
}

.searched-page-number.active {
  background-color: #045096;
  color: white;
}

.searched-page-number:hover {
  background-color: #f0f0f0;
}

.searched-page-number.active:hover {
  background-color: #045096;
}

@media (max-width: 1200px) {
  .searched-attachments__icon {
    width: 70px; 
    height: 90px; 
    object-fit: cover; 
    border-radius: 10px;
  }
}

@media (max-width: 1000px) {
  .search-results-header {
    width: 99%;
    max-width: 100%;
    margin-right: -5%;
  }

  .company-searched-picture {
    width: 80px;
    height: 80px;
  }

  .searched-attachments__icon {
    width: 90px; 
    height: 90px; 
    object-fit: cover; 
    margin-right: 10px;
    border-radius: 10px;
  }
}

@media (max-width: 750px) {
  .searched-attachments__icon {
    width: 70px; 
    height: 90px; 
    object-fit: cover; 
    border-radius: 10px;
  }
}

@media (max-width: 749px) and (min-width: 601px) {
  .searched-attachments__icon {
    width: 50px; 
    height: 90px; 
    object-fit: cover; 
    border-radius: 10px;
  }

  .searched-attachments {
    margin-left: 60px;
  }

  .no-searched-results {
    width: 95%;
  }

  .search-results-title {
    width: 95%;
  }
}

@media (max-width: 600px) { 
  .searched-attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .searched-attachments__icon {
    width: 40px; 
    height: 40px; 
    max-height: 50px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: -10px;
  }

  .no-searched-results {
    width: 95%;
  }

  .search-results-title {
    width: 95%;
  }

  .searched-company-id {
    font-size: 1.1rem;
  }

  .searched-company-location {
    font-size: 12px;
  }
  
  .searched-location-icon {
    width: 12px;
    height: 12px;
  }
  
  .past-review-title {
    flex-direction: row;
  }
  
  .company-starting-price {

    font-size: 12px;
  }
  
  .searched-company-town {
    font-size: 12px;
  }
  
  .past-review-company-type {
    font-size: 13px;
  }
  
  .company-searched-picture {
    width: 17%;
    height: 17%;
  }
}

@media (max-width: 550px) { 
  .searched-attachments {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-right: 10px;
  }

  .searched-attachments__icon {
    width: 40px; 
    height: 40px; 
    max-height: 50px;
    object-fit: cover;
    border-radius: 10px;
  }

  .searched-attachments {
    margin-left: 70px;
  }
}

@media (max-width: 389px) { 
  .searched-attachments__icon {
    width: 0px; 
    height: 0px; 
  }
}