.search-company {
  flex: 1;
  position: relative;
}

.search-company input {
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  box-sizing: border-box;
}

.search-company ul {
  list-style-type: none;
  padding: 0;
  margin: -10px 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 10;
}

.search-company li {
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-company li:hover {
  background-color: #f5f5f5;
}

.search-company li:last-child {
  border-bottom: none;
}

.search-company img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

.search-company div {
  text-align: left;
}

.search-company strong {
  font-size: 1rem;
  margin-right: 10px;
}

.search-company p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
}

.search-company span {
  margin-left: auto;
  font-size: 0.85rem;
  color: #999;
}
