.invoice-zoom-full-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
}

.invoice-zoom-container {
  background-color: #f1f1f1;
  border-radius: 10px;
  margin-top: 40px;
  padding: 20px;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 120px;
  width: 70%;
}

.invoice-zoom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95.5%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.invoice-zoom-company-details {
  display: flex;
  align-items: center;
}

.invoice-zoom-company-profile {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.invoice-zoom-company-profile img {
  width: 70px; 
  height: 70px; 
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.invoice-zoom-company-name a {
  text-decoration: none;
  color: #666; 
  font-size: 1.4rem;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.invoice-zoom-company-name a:hover {
  color: #222;
}

.invoice-zoom-total-amount-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  margin-right: 20px;
}

.invoice-zoom-total-amount {
  font-size: 1.3rem;
  font-weight: bold;
  color: #666;
  margin: 0; 
  font-family: 'Poppins', sans-serif;
}

.invoice-zoom-sections {
  display: flex;
  justify-content: space-between;
}

.invoice-zoom-left,
.invoice-zoom-right {
  width: 44%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.invoice-zoom-content {
  margin-bottom: 40px;
}

.invoice-zoom-content-header {
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}

.no-more-payments {
  flex-direction: column;
  text-align: center;
  font-size: 1.5rem;
  color: #6f6d6d;
  margin-bottom: 10px;
  margin-top: 10px;
}

.invoice-zoom-due-date {
  color: #666;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

.payment-details {
  margin-bottom: 40px;
  margin-left: 15%;
  margin-top: 20px;
}

.payment-details div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: left;
  font-size: 1.1rem;
  width: 80%;
  font-family: 'Poppins', sans-serif;
}

.invoice-zoom-pay-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.invoice-zoom-pay-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  position: relative;
}

.invoice-zoom-pay-button {
  --primary-color: #045096;
  --hovered-color: #23313d;
  position: relative;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  padding: 7px 40px;
  font-size: 18px;
  border: none;
  background: none;
  cursor: pointer;
  color: var(--primary-color);
  transition: all 0.3s ease;
  border-radius: 5px;
  font-family: 'Raleway', sans-serif;
}

.invoice-zoom-pay-button p {
  margin: 0;
  position: relative;
  font-size: 16px;
  color: var(--primary-color);
}

.invoice-zoom-pay-button::after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -7px;
  background: var(--hovered-color);
  height: 2px;
  transition: 0.3s ease-out;
}

.invoice-zoom-pay-button p::before {
  position: absolute;
  content: attr(data-text);
  width: 0%;
  inset: 0;
  color: var(--hovered-color);
  overflow: hidden;
  transition: 0.3s ease-out;
}

.invoice-zoom-pay-button:hover::after,
.invoice-zoom-pay-button.active::after {
  width: 100%;
}

.invoice-zoom-pay-button:hover p::before,
.invoice-zoom-pay-button.active p::before {
  width: 100%;
}

.invoice-zoom-pay-button:hover svg,
.invoice-zoom-pay-button.active svg {
  transform: translateX(4px);
  color: var(--hovered-color);
}

.invoice-zoom-pay-button svg {
  color: var(--primary-color);
  transition: 0.2s;
  position: relative;
  width: 15px;
  transition-delay: 0.2s;
}

.invoice-zoom-pay-button.active {
  --primary-color: var(--hovered-color);
}

.or-text {
  text-align: center;
  margin: 15px 0;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}

.custom-payment-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.custom-payment-wrapper input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.85rem;
  width: 100%;
  padding-right: 40px;
}

.pay-smaller-text {
  right: -14px;
  text-align: right; 
  margin-top: 0.5rem;
  cursor: pointer;
  position: absolute;
  color: #045096;
  font-family: 'Raleway', sans-serif;
}

.pay-smaller-text:hover {
  text-decoration: underline;
}

.invoice-error-message {
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.success-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9990;
}

.success-message {
  color: green;
  font-size: 1.4rem;
  margin-top: 10px;
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: rgb(211, 211, 211);
  padding: 20px;
  border-radius: 10px;
  outline: none;
}

.active-payment-method-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: 13%;
  margin-right: 13%;
  font-size: 1rem;
  color: #333;
}

.card-brand {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  color: #333;
}

.card-expiry {
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  color: #555;
}

.invoice-zoom-payment-schedule-grid {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.invoice-zoom-payment-schedule-grid th,
.invoice-zoom-payment-schedule-grid td {
  border: 1px solid #b0b0b0;
  padding: 8px;
  text-align: left;
}

.invoice-zoom-payment-schedule-grid th {
  background-color: #f2f2f2;
  font-weight: bold;
}


.invoice-zoom-payment-schedule {
  margin-bottom: 20px;
  text-align: center;
}

.invoice-zoom-payment-schedule-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.invoice-zoom-payment-schedule-list {
  list-style: none;
  padding: 0;
  text-align: left; 
  font-family: 'Poppins', sans-serif;
}

.invoice-zoom-payment-schedule-list li {
  font-size: 1rem;
  color: #666;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
}

.completed-payments-section {
  margin-top: 20px;
  text-align: center;
}

.completed-payments-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.completed-payments-section table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-family: 'Poppins', sans-serif;
}

.completed-payments-section th,
.completed-payments-section td {
  border: 1px solid #b0b0b0;
  padding: 8px;
  text-align: left;
}

.completed-payments-section th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.completed-payments-section td {
  font-size: 1rem;
  color: #666;
}

.completed-payments-section .status-icon img {
  width: 20px;
  height: 20px;
}

.no-schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  margin: 0 auto;
  color: #6f6d6d;
  margin-bottom: 10px;
  margin-top: 10px;
}

.no-schedule img {
  margin-top: 10px;
  height: 60px;
  width: 60px;
}

.invoice-zoom-invoice-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px; 
}

.no-invoice {
  align-self: center;
  flex-direction: column;
  text-align: center;
  font-size: 1.5rem;
  color: #6f6d6d;
  margin-bottom: 10px;
  margin-top: 10px;
}

.invoice-zoom-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.expand-button {
  border: 2px solid #045096;
  background-color: white;
  color: #045096;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 10px;
}

.expand-button:hover {
  border-color: #23313d;
  color: #23313d;
}

.invoice-modal {
  position: absolute;
  top: 60%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
  max-height: 90vh; 
  overflow: auto; 
}

.invoice-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}

.invoice-modal-content {
  margin-top: 20px;
}

.invoice-close-modal-button {
  border: 2px solid #045096;
  background-color: white;
  color: #045096;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: sticky;
  top: 0; 
  float: right;
  z-index: 1000;
}

.invoice-close-modal-button:hover {
  border-color: #23313d;
  color: #23313d;
}

.review-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9990;
}

.review-modal-content {
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: rgb(211, 211, 211);
  padding: 20px;
  border-radius: 10px;
  outline: none;
  max-height: 90vh;
  overflow: auto;
}

.review-modal-content h1 {
  color: green;
  font-size: 1.4rem;
  margin-top: 10px;
  margin-bottom: 50px;
}

.review-modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
}

.review-modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-left: 2.5%;
  height: 50px;
  width: 95%;
  background-color: white;
  border-radius: 15px 15px 15px 15px;
  z-index: 3;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #b0b0b0;
  border-right: 1px solid #b0b0b0;
  border-bottom: 1px solid #b0b0b0;
}

.write-review-button {
  color: #045096;
  padding: 10px 60px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.no-thanks-button {
  color: #045096;
  padding: 10px 60px;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  background-color: white;
  border: none;
  border-radius: 15px 15px 15px 15px;
}

.write-review-button:hover {
  background-color: #f1f0f0;
  color: #23313d;
}

.no-thanks-button:hover {
  background-color: #f1f0f0;
  color: #ff0000;
}

@media (max-width: 1050px) {
  .invoice-zoom-sections {
    flex-direction: column;
    align-items: center;
  }

  .invoice-zoom-left,
  .invoice-zoom-right {
    width: 95%;
    margin-bottom: 20px;
  }

  .invoice-zoom-pay-button {
    width: 40%;
  }

  .invoice-zoom-container {
    margin-top: 5px;
    width: 90%;
  }

  .invoice-zoom-header {
    width: 95.5%;
  }

  .completed-payments-section {
    width: 95%;
    margin: 20px auto;
  }
}

@media (max-width: 900px) {
  .invoice-zoom-container {
    margin-top: 5px;
    width: 100%;
  }

  .invoice-zoom-header {
    width: 94.5%;
  }
}

@media (max-width: 570px) {
  .invoice-zoom-company-name {
    font-size: 1.2rem;
  }
  
  .invoice-zoom-total-amount {
    font-size: 1.2rem; 
  }

  .no-schedule img {
    height: 50px;
    width: 50px;
  }

  .invoice-zoom-company-profile img {
    width: 60px; 
    height: 60px; 
  }

  .completed-payments-title {
    font-size: 1rem;
  }

  .completed-payments-section th,
  .completed-payments-section td {
    font-size: 0.9rem;
  }

  .completed-payments-section .status-icon img {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 430px) {
  .invoice-zoom-company-name {
    font-size: 1rem;
  }
  
  .invoice-zoom-total-amount {
    font-size: 1rem; 
  }

  .invoice-zoom-company-profile img {
    width: 50px; 
    height: 50px; 
    align-items: left;
  }

  .completed-payments-title {
    font-size: 0.9rem;
  }

  .completed-payments-section th,
  .completed-payments-section td {
    font-size: 0.8rem;
  }

  .completed-payments-section .status-icon img {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 390px) {
  .invoice-zoom-company-name {
    font-size: 0.8rem;
  }

  .invoice-zoom-total-amount {
    font-size: 0.8rem; 
  }

}