.sign-in-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sign-in-modal-overlay .modal-content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px; 
  max-width: 90%; 
  max-height: 390px;
  box-sizing: border-box;
  position: relative;
}

.sign-in-modal-overlay .close-button {
  position: absolute; 
  top: 7px; 
  right: 10px; 
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: black;
  background-color: white;
}

.sign-in-modal-overlay .close {
  float: right;
  font-size: 1.5rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.modal-sign-in-head {
  margin-top: 0;
  text-align: center;
  color: rgb(41, 41, 41);
  font-weight: 600;
  font-size: 1.5rem;
}

.sign-in-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: black;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box; 
  font-family: 'Poppins', sans-serif;
}

.sign-in-modal-overlay .error {
  color: red;
  font-size: 0.9rem;
  margin-bottom: -20px;
  text-align: center;
}

.forgot-password-link {
  background: none;
  border: none;
  color: #0056b3;
  cursor: pointer;
  padding: 0px;
  text-align: left;
  display: flex;
  margin-top: 10px;
  font-size: 0.8rem;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

.forgot-password-link:hover {
  text-decoration: underline;
  color: #0056b3;
}

.sign-in-button {
  background-color: #23313d;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Raleway', sans-serif;
}

.sign-in-modal-overlay .sign-in-button:hover {
  background-color: #333a41;
  color: white;
}

.create-account {
  text-align: center;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
}

.create-account-button {
  background: none;
  border: none;
  color: #0056b3;
  cursor: pointer;
  padding: 0px;
  text-decoration: none;
}

.create-account-button:hover {
  text-decoration: underline;
  color: #0056b3;
}

@media (max-width: 500px) {
  .modal-content {
    width: 90%; 
  }
}

